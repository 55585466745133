import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import CategoryModel from '@/views/service/barcode/mapping/model/CategoryModel';
import BrandModel from '@/views/service/barcode/mapping/model/BrandModel';
import MapperModel from '@/views/service/barcode/mapping/model/MapperModel';

export default class BarcodeMappingListViewModel {
  constructor() {
    this.dataList = []; // 맵팽 되어있는 전체 데이터
    this.mapperModel = new MapperModel();
    this.dataViewModel = {
      CATEGORY: {
        dataList: [],
        searchData: {
          searchParams: {
            direction: 'ASC',
            pageSize: 200,
          },
          value: '',
        },
        paginationData: {
          totalCount: 0,
        },
        value: null,
        detailData: new CategoryModel(),
        validData: {
          category_name: {
            value: 'category_name',
            valueType: '',
            isValid: false,
          },
          category_description: {
            value: 'category_description',
            valueType: '',
            isValid: false,
          },
          color: {
            value: 'color',
            valueType: '',
            isValid: false,
          },
          icon_img_list: {
            value: 'icon_img_list',
            valueType: 'array',
            isValid: false,
          },
        },
      },
      BRAND: {
        dataList: [],
        searchData: {
          searchParams: {
            direction: 'ASC',
            pageSize: 200,
          },
          value: '',
        },
        paginationData: {
          totalCount: 0,
        },
        value: null,
        detailData: new BrandModel(),
        registerValidData: {
          brand_code: {
            value: 'brand_code',
            valueType: '',
            isValid: false,
          },
          goods_code: {
            value: 'goods_code',
            valueType: '',
            isValid: false,
          },
          brand_logo_img_list: {
            value: 'brand_logo_img_list',
            valueType: 'array',
            isValid: false,
          },
          brand_name: {
            value: 'brand_name',
            valueType: '',
            isValid: false,
          },
          payment_category: {
            value: 'payment_category',
            valueType: '',
            isValid: false,
          },
          payback_percent: {
            value: 'payback_percent',
            valueType: 'numberCommaDot',
            isValid: false,
          },
          use_info: {
            value: 'use_info',
            valueType: '',
            isValid: false,
          },
        },
        modifyValidData: {
          status: {
            value: 'status',
            valueType: '',
            isValid: false,
          },
          brand_logo_img_list: {
            value: 'brand_logo_img_list',
            valueType: 'array',
            isValid: false,
          },
          brand_name: {
            value: 'brand_name',
            valueType: '',
            isValid: false,
          },
          payment_category: {
            value: 'payment_category',
            valueType: '',
            isValid: false,
          },
          payback_percent: {
            value: 'payback_percent',
            valueType: 'numberCommaDot',
            isValid: false,
          },
          use_info: {
            value: 'use_info',
            valueType: '',
            isValid: false,
          },
        },
      },
    };
    this.groupCategoryDrawData = {
      CATEGORY: {
        title: '카테고리',
        id: 'CATEGORY',
        index: 0,
        titleList: [
          { title: '순서' },
          { title: '상태' },
          { title: '아이콘' },
          { title: '카테고리명', style:'width:80px;margin:0 14px 0 12px;text-align:left' },
          { title: '타이틀', isFill: true },
        ],
        isDragable: true,
        isHead: true,
        isAddBtn: true,
        isSearchBtn: false,

        // event
        contextMenu: [
          {
            icon: '✍️',
            text: '카테고리 수정',
            event: data => this.onClickModify('CATEGORY',data),
          },
        ],
        isRegisterPopup: false,
      },
      BRAND: {
        title: '브랜드',
        id: 'BRAND',
        index: 1,
        titleList: [
          { title: '순서' },
          { title: '상태' },
          { title: '로고' },
          { title: '브랜드명', isFill: true },
          { title: '결제카테고리' },
          { title: '페이백' },
        ],
        isDragable: true,
        isHead: true,
        isAddBtn: true,
        isSearchBtn: false,
        isRegisterPopup: false,
      },
    };
    this.searchParams = {
      page: 0,
      pageSize: 100,
      direction: 'ASC',
    };
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };
  }

  init() {
    this.getBarcodeMappingInfoList();
  }

  // 리스트 추가
  onClickAdd(categoryId){
    this.resetDetilModel(categoryId);
    switch(categoryId){
      case 'CATEGORY': {
        this.groupCategoryDrawData.CATEGORY.isRegisterPopup = true;
        break
      }
      case 'BRAND': {
        this.groupCategoryDrawData.BRAND.isRegisterPopup = true;
        break
      }
    }
  }
  // 리스트 수정
  onClickModify(categoryId,data){
    switch(categoryId){
      case 'CATEGORY': {
        this.groupCategoryDrawData.CATEGORY.isRegisterPopup = true;
        this.dataViewModel.CATEGORY.detailData.setData(data);
        break
      }
      // case 'BRAND': {
      //   this.groupCategoryDrawData.BRAND.isRegisterPopup = true;
      //   this.dataViewModel.BRAND.detailData.setData(data);
      //   break
      // }
    }
  }
  // 항목 선택
  onSelectCategoryItem(categoryId, rowData) {
    let beforeValue = null;
    let setValueEvent = undefined;
    let getDataEvent = undefined;

    switch (categoryId) {
      case 'CATEGORY': {
        beforeValue = this.dataViewModel.CATEGORY.value;
        setValueEvent = () => {
          this.dataViewModel.CATEGORY.value = rowData.uid;
          this.dataViewModel.BRAND.value = '';
        };
        getDataEvent = () => {
          this.getBrandList(rowData.uid);
        };
        break;
      }
      case 'BRAND': {
        beforeValue = this.dataViewModel.BRAND.value;
        setValueEvent = () => {
          this.dataViewModel.BRAND.value = rowData.brand_code;
        };
        getDataEvent = () => {
          this.groupCategoryDrawData.BRAND.isRegisterPopup = true;
        };
        break;
      }
    }
    if (beforeValue != rowData.uid) {
      setValueEvent();
      getDataEvent();
      this.dataViewModel[categoryId].detailData.setData(rowData);
    } else if (categoryId === 'BRAND') {
      setValueEvent();
      getDataEvent();
    }
  }
  // 팝업 완료
  onClickPopupComplete(categoryId, data) {
    switch (categoryId) {
      case 'CATEGORY': {
        const isModify = Boolean(this.dataViewModel[categoryId].detailData.uid);
        const callBack = () => {
          this.groupCategoryDrawData.CATEGORY.isRegisterPopup = false;
        };
        if (isModify) {
          this.postCategoryModify(data, callBack);
        } else {
          this.postCategoryRegister(data, callBack);
        }
        break;
      }
      case 'BRAND': {
        const isModify = Boolean(this.dataViewModel[categoryId].detailData.uid);
        const callBack = () => {
          this.groupCategoryDrawData.BRAND.isRegisterPopup = false;
        };
        if (isModify) {
          this.postBrandModify(data, callBack);
        } else {
          this.postBrandRegister(data, callBack);
        }
        break;
      }
    }
  }
  // 팝업 닫기
  onClickPopupClose(categoryId) {
    switch (categoryId) {
      case 'CATEGORY': {
        this.groupCategoryDrawData.CATEGORY.isRegisterPopup = false;
        this.resetDetilModel('CATEGORY');
        break;
      }
      case 'BRAND': {
        this.groupCategoryDrawData.BRAND.isRegisterPopup = false;
        this.resetDetilModel('BRAND');
        break;
      }
    }
  }
  // 리셋 모델
  resetDetilModel(modelId) {
    switch (modelId) {
      case 'CATEGORY': {
        // this.CATEGORY.value = '';
        this.dataViewModel.CATEGORY.detailData = new CategoryModel();
        break;
      }
      case 'BRAND': {
        // this.BRAND.value = '';
        this.dataViewModel.BRAND.detailData = new BrandModel();
        break;
      }
    }
  }
  // 순서변경
  onModifyOrder(orderData) {
    switch (orderData.type) {
      case 'CATEGORY': {
        this.postCategoryPosition(orderData.id, orderData.newIndex);
        break;
      }
      case 'BRAND': {
        this.postBrandPosition(orderData.id, orderData.newIndex);
        break;
      }
    }
  }
  // 브랜드 목록
  getBrandList(uid) {
    const findItem = this.dataList.find(
      item => item.barcode_category_dto.uid === uid,
    );
    this.dataViewModel.BRAND.dataList = findItem
      ? findItem.barcode_brand_dto_list
      : [];
    this.dataViewModel.BRAND.totalCount =
      findItem.barcode_brand_dto_list.length;
  }
  // [API] 바코드 맵핑 데이터
  getBarcodeMappingInfoList() {
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.BARCODE_INFO_LIST}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        const brandListForDtoList = resultData.body.brand_list_for_category;
        this.dataList = brandListForDtoList;
        this.dataViewModel.CATEGORY.dataList = brandListForDtoList.map(
          item => item.barcode_category_dto
        );
        this.dataViewModel.CATEGORY.totalCount = brandListForDtoList.length;

        const beforeValue = this.dataViewModel.CATEGORY.value;
        if (beforeValue) {
          this.getBrandList(beforeValue)
        }
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

  // 카테고리 등록
  postCategoryRegister(dataBody,callBack) {
    const path = `${apiPath.BARCODE_CATEGORY_REGISTER}`;
    const data = {
      body: dataBody,
      header: {
        request_at: new Date(),
      },
    };
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          '바코드 카테고리가 등록되었습니다',
        );
        this.getBarcodeMappingInfoList();
        if(callBack){
          callBack();
        }
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 카테고리 수정
  postCategoryModify(dataBody,callBack) {
    const model = this.dataViewModel.CATEGORY.detailData;
    const path = `${apiPath.BARCODE_CATEGORY_MODIFY.format(model.uid)}`;
    const data = {
      body: dataBody,
      header: {
        request_at: new Date(),
      },
    };
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          '바코드 카테고리가 수정되었습니다',
        );
        this.getBarcodeMappingInfoList();
        if(callBack){
          callBack();
        }
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 카테고리 순서 변경
  postCategoryPosition(uid, new_seq){
    const path = `${apiPath.BARCODE_CATEGORY_POSITION.format(uid)}`;
    const data = {
      body: {
        category_seq: new_seq
      },
      header: {
        request_at: new Date(),
      },
    };
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          '바코드 카테고리의 순서가 변경되었습니다',
        );
        this.getBarcodeMappingInfoList();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

  // 브랜드 등록
  postBrandRegister(dataBody,callBack) {
    dataBody.category_uid = this.dataViewModel.CATEGORY.value;
    const path = `${apiPath.BARCODE_BRAND_REGISTER}`;
    const data = {
      body: dataBody,
      header: {
        request_at: new Date(),
      },
    };
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          '바코드 카테고리가 등록되었습니다',
        );
        this.getBarcodeMappingInfoList();
        if(callBack){
          callBack();
        }
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 브랜드 수정
  postBrandModify(dataBody,callBack) {
    dataBody.category_uid = this.dataViewModel.CATEGORY.value;
    const path = `${apiPath.BARCODE_BRAND_MODIFY.format(
      this.dataViewModel.BRAND.value,
      dataBody.category_uid
    )}`;
    const data = {
      body: dataBody,
      header: {
        request_at: new Date(),
      },
    };
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          '바코드 카테고리가 수정되었습니다',
        );
        this.getBarcodeMappingInfoList();
        if(callBack){
          callBack();
        }
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // 브랜드 순서 변경
  postBrandPosition(brand_code, new_seq){
    const categoryUid = this.dataViewModel.CATEGORY.value;
    const path = `${apiPath.BARCODE_BRAND_POSITION.format(brand_code, categoryUid)}`;
    const data = {
      body: {
        brand_seq: new_seq
      },
      header: {
        request_at: new Date(),
      },
    };
    POST_REQUEST(path, data).then(
      success => {
        const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          '바코드 브랜드의 순서가 변경되었습니다',
        );
        this.getBarcodeMappingInfoList();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
