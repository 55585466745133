const routerComponent = {
  LOGIN : () => import('@/views/login/view/Login.vue'),
  MY: () => import('@/views/my/view/My.vue'),

  // 대시보드
  DASHBOARD_DASHBOARD: () => import('@/views/dashboard/dashboard/view/Dashboard.vue'),
  // --- service ---
  // 회원관리
  SERVICE_USER_SEARCH_LIST: () => import('@/views/service/user/view/search/UserSearchList.vue'),
  SERVICE_USER_SEARCH_DETAIL: () => import('@/views/service/user/view/search/UserDetail.vue'),
  // 회원관리+
  SERVICE_USER_PLUS_LIST: () => import('@/views/service/user/view/plus/UserPlusList.vue'),
  SERVICE_USER_PLUS_DETAIL: () => import('@/views/service/user/view/plus/UserDetail.vue'),
  // 탈퇴회원관리
  SERVICE_USER_LEAVE_LIST: () => import('@/views/service/user/view/leave/UserLeaveList.vue'),
  SERVICE_USER_LEAVE_DETAIL: () => import('@/views/service/user/view/leave/UserDetail.vue'),
  // 멤버십 구매내역 조회
  SERVICE_MEMBERSHIP_PAYMENTS_LIST: () => import('@/views/service/membership/payments/view/MembershipPaymentsList.vue'),
  // 문의관리
  SERVICE_INQUIRY_LIST: () => import('@/views/service/inquiry/view/InquiryList.vue'),
  SERVICE_INQUIRY_DETAIL: () => import('@/views/service/inquiry/view/InquiryDetail.vue'),
  // 카드관리
  SERVICE_CARD_SEARCH: () => import('@/views/service/card/view/CardApplySearch.vue'),
  SERVICE_CARD_LIST: () => import('@/views/service/card/view/CardApplyList.vue'),
  SERVICE_CARD_DETAIL: () => import('@/views/service/card/view/CardApplyDetail.vue'),
  // 약관관리
  SERVICE_TERM_LIST: () => import('@/views/service/term/view/TermList.vue'),
  SERVICE_TERM_DETAIL: () => import('@/views/service/term/view/TermDetail.vue'),
  SERVICE_TERM_REGISTER: () => import('@/views/service/term/view/TermRegister.vue'),
  SERVICE_TERM_MODIFY: () => import('@/views/service/term/view/TermRegister.vue'),
  // FAQ관리
  SERVICE_FAQ: () => import('@/views/service/faq/view/Faq.vue'),
  SERVICE_FAQ_REGISTER: () => import('@/views/service/faq/view/FaqRegister.vue'),
  SERVICE_FAQ_MODIFY: () => import('@/views/service/faq/view/FaqRegister.vue'),
  // 포인트관리
  SERVICE_POINT_INDIVIDUAL: () => import('@/views/service/point/view/PointIndividualReward.vue'),
  SERVICE_POINT_REWARD: () => import('@/views/service/point/view/PointReward.vue'),
  SERVICE_POINT_HISTORY: () => import('@/views/service/point/view/PointHistoryList.vue'),
  // 입금이체 은행관리
  SERVICE_BANK: () => import('@/views/service/bank/view/Bank.vue'),
  // 바코드 관리
  SERVICE_BARCODE_MAPPING: () => import('@/views/service/barcode/mapping/view/BarcodeBrandMapping.vue'),
  SERVICE_BARCODE_MAINTENANCE: () => import('@/views/service/barcode/maintenance/view/BarcodeMaintenance.vue'),

  // --- app ---
  // 점검관리
  APP_INSPECTION: () => import('@/views/app/inspection/view/Inspection.vue'),
  // 업데이트관리
  APP_UPDATE_LIST: () => import('@/views/app/update/view/UpdateList.vue'),
  APP_UPDATE_DETAIL: () => import('@/views/app/update/view/UpdateDetail.vue'),
  APP_UPDATE_REGISTER: () => import('@/views/app/update/view/UpdateRegister.vue'),
  // APP_UPDATE_MODIFY: () => import('@/views/app/update/view/UpdateModify.vue'),
  // 알림
  APP_ALARM_LIST: () => import('@/views/app/alarm/view/AlarmList.vue'),
  APP_ALARM_DETAIL: () => import('@/views/app/alarm/view/AlarmDetail.vue'),
  APP_ALARM_REGISTER: () => import('@/views/app/alarm/view/AlarmRegister.vue'),
  APP_ALARM_MODIFY: () => import('@/views/app/alarm/view/AlarmRegister.vue'),
  // 베너관리
  APP_BANNER_LIST: () => import('@/views/app/banner/view/BannerList.vue'),
  APP_BANNER_DETAIL: () => import('@/views/app/banner/view/BannerDetail.vue'),
  APP_BANNER_REGISTER: () => import('@/views/app/banner/view/BannerRegister.vue'),
  // APP_BANNER_MODIFY: () => import('@/views/app/banner/view/BannerModify.vue'),

  // --- cs ---
  // 회원조회
  CS_USER_BYUSERDATA_LIST: () => import('@/views/cs/user/byuserdata/view/CsUserByUserDataList.vue'),
  CS_USER_BYBANK: () => import('@/views/cs/user/bybank/view/CsUserByBank.vue'),
  CS_USER_BYAPPROVE: () => import('@/views/cs/user/byapprove/view/CsUserByApprove.vue'),
  CS_CARDRETURN_LIST: () => import('@/views/cs/card/return/view/CardReturnList.vue'),
  CS_KBCARDPURCHASE_LIST: () => import('@/views/cs/card/purchase/view/KbCardPurchaseList.vue'),
  CS_TAXRETURN_LIST: () => import('@/views/cs/taxReturn/view/TaxReturnList.vue'),
  CS_BLACKLIST_SEARCH: () => import('@/views/cs/blacklist/view/BlackListSearch.vue'),
  
  // --- store ---
  // 가맹점관리
  STORE_STORE_LIST: () => import('@/views/store/store/view/StoreList.vue'),
  STORE_STORE_REGISTER: () => import('@/views/store/store/view/StoreRegister.vue'),
  STORE_STORE_MODIFY: () => import('@/views/store/store/view/StoreRegister.vue'),
  // 가맹 계약 변경요청
  STORE_CONTRACT_LIST: () => import('@/views/store/contract/view/ContractList.vue'),
  // 주문 및 결제관리
  STORE_ORDER_LIST: () => import('@/views/store/order/view/OrderList.vue'),
  // 개별 거래 조회
  STORE_TRADE_LIST: () => import('@/views/store/trade/view/TradeList.vue'),
  // 정산관리
  STORE_SETTLE_LIST: () => import('@/views/store/settle/view/SettleList.vue'),
  STORE_SETTLE_DETAIL: () => import('@/views/store/settle/view/SettleDetail.vue'),

  // --- landing ---
  // 출시알림 이벤트 통계
  LANDING_STATISTIC: () => import('@/views/landing/statistic/view/Statistic.vue'),
  // 레몬머니레터
  LANDING_LETTER_LIST: () => import('@/views/landing/letter/view/LetterList.vue'),
  LANDING_LETTER_REGISTER: () => import('@/views/landing/letter/view/LetterRegister.vue'),
  LANDING_LETTER_MODIFY: () => import('@/views/landing/letter/view/LetterRegister.vue'),
  LANDING_LETTER_DETAIL: () => import('@/views/landing/letter/view/LetterDetail.vue'),
  // 공지사항
  LANDING_NOTICE_LIST: () => import('@/views/landing/notice/view/NoticeList.vue'),
  LANDING_NOTICE_REGISTER: () => import('@/views/landing/notice/view/NoticeRegister.vue'),
  LANDING_NOTICE_MODIFY: () => import('@/views/landing/notice/view/NoticeRegister.vue'),
  LANDING_NOTICE_DETAIL: () => import('@/views/landing/notice/view/NoticeDetail.vue'),

  // --- admin ---
  // 직원관리
  ADMIN_MEMBER_LIST: () => import('@/views/admin/member/view/MemberList.vue'),
  ADMIN_MEMBER_DETAIL: () => import('@/views/admin/member/view/MemberDetail.vue'),
  ADMIN_MEMBER_REGISTER: () => import('@/views/admin/member/view/MemberRegister.vue'),
  ADMIN_MEMBER_MODIFY: () => import('@/views/admin/member/view/MemberModify.vue'),
  // 시스템 로그
  ADMIN_SYSTEM_LIST: () => import('@/views/admin/system/view/SystemList.vue'),
  // 데이터 조회
  ADMIN_DB_LIST: () => import('@/views/admin/db/view/DbList.vue'),

  // --- 모니터링 ---
  MONITORING_AML: () => import('@/views/monitoring/aml/view/MonitoringAml.vue'),

  //  --- guide ---
  GUIDE_COMPONETS_FORMS: () => import('@/views/guide/components/forms/Forms.vue'),
  GUIDE_COMPONETS_TAB: () => import('@/views/guide/components/tab/Tab.vue'),
  GUIDE_COMPONETS_SEARCH: () => import('@/views/guide/components/search/Search.vue'),
  GUIDE_COMPONETS_BOARD: () => import('@/views/guide/components/board/Board.vue'),
  GUIDE_COMPONETS_TABLE: () => import('@/views/guide/components/table/Table.vue'),
  GUIDE_COMPONETS_CATEGORY: () => import('@/views/guide/components/category/Category.vue'),
  GUIDE_COMPONETS_CALENDAR: () => import('@/views/guide/components/calendar/Calendar.vue'),
  GUIDE_COMPONETS_BUTTONS: () => import('@/views/guide/components/buttons/Buttons.vue'),
  GUIDE_COMPONETS_BADGES: () => import('@/views/guide/components/badges/Badges.vue'),
  GUIDE_COMPONETS_WIDGETS: () => import('@/views/guide/components/widgets/Widgets.vue'),
  GUIDE_COMPONETS_STEP: () => import('@/views/guide/components/step/Step.vue'),
  GUIDE_ICONS: () => import('@/views/guide/icons/Icons.vue'),
  GUIDE_CHARTS: () => import('@/views/guide/charts/chart/Chart.vue'),
  GUIDE_LAYER: () => import('@/views/guide/layer/Layer.vue'),
}

export default routerComponent;