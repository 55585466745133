<template>
  <Popup
    :title="`${detailData.name} 회원 상세정보`"
    :maxWidth="3000"
    closeBtnText="닫기"
    @onClickClose="onClickClose()">
    <TableView class="non_margin_top">
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
        <col style="width:160px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">회원 기본정보</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="copy ID"
            @onClickBtn="viewModel.onClickCopyBasicId()"/>
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="copy All"
            @onClickBtn="viewModel.onClickCopyBasicAll()"/>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>pay ID</th>
          <td>
            <div class="group_form">
            <span class="txt_tbl item_form_fill">{{ detailData.payUserId }}</span>
            <Button
              btnSize="xsmall"
              btnStyle="secondary_border"
              text="copy"
              @onClickBtn="viewModel.onClickCopy(detailData.payUserId,'pay ID')"/>
            </div>
          </td>
          <th>pay 계정상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="detailData.payUserStatus | convertIdToText('user_status_name')"
                :badgeStyle="detailData.payUserStatus | convertIdToColor('cs_user_status_name')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>퍼핀 회원 ID</th>
          <td>
            <div class="group_form">
            <span class="txt_tbl item_form_fill">{{ detailData.firfinUserId }}</span>
            <Button
              btnSize="xsmall"
              btnStyle="secondary_border"
              text="copy"
              @onClickBtn="viewModel.onClickCopy(detailData.firfinUserId,'퍼핀 회원 ID')"/>
            </div>
          </td>
          <th>firfin 계정상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="detailData.firfinUserStatus | convertIdToText('user_status_name')"
                :badgeStyle="detailData.firfinUserStatus | convertIdToColor('cs_user_status_name')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>퍼핀 UID</th>
          <td>
            <div class="group_form">
            <span class="txt_tbl item_form_fill">{{ detailData.firfinUserUid }}</span>
            <Button
              btnSize="xsmall"
              btnStyle="secondary_border"
              text="copy"
              @onClickBtn="viewModel.onClickCopy(detailData.firfinUserUid,'퍼핀 UID')"/>
            </div>
          </td>
          <th>멤버십 상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                v-if="detailData.memberships.length > 0"
                :text="detailData.memberships[0].status | convertIdToText('user_status_name')"
                :badgeStyle="detailData.memberships[0].status | convertIdToColor('user_status_name')"
                :badgeFill="false" />
              <Badge
                v-else
                :text="'UNREGISTERED' | convertIdToText('user_status_name')"
                :badgeStyle="'UNREGISTERED' | convertIdToColor('user_status_name')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>가족 ID</th>
          <td>
            <div class="group_form">
            <span class="txt_tbl item_form_fill">{{ detailData.familyGroupId }}</span>
            <Button
              btnSize="xsmall"
              btnStyle="secondary_border"
              text="copy"
              @onClickBtn="viewModel.onClickCopy(detailData.familyGroupId,'가족 ID')"/>
            </div>
          </td>
          <th>가족 상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="detailData.familyStatus | convertIdToText('cs_family_status')"
                :badgeStyle="detailData.familyStatus | convertIdToColor('cs_family_status')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>역할(대표유무)</th>
          <td>
            <span :class="`txt_tbl fw_bold tc_${$options.filters.convertIdToColor(detailData.familyRole, 'cs_user_role')}`">{{ detailData.familyRole | convertIdToText('user_role') }}{{ detailData.familyPermission | convertIdToText('user_family_leader') }}</span>
          </td>
          <th>회원이름</th>
          <td>
            <span class="txt_tbl">{{ detailData.name }}</span>
          </td>
        </tr>
        <tr>
          <th>생년월일</th>
          <td>
            <span class="txt_tbl">{{ detailData.dateOfBirth | convertDateFormat('YYYY-MM-DD') }}</span>
          </td>
          <th>휴대전화</th>
          <td>
            <div class="group_form">
            <span class="txt_tbl item_form_fill">{{ detailData.phone | convertPhoneNember }}</span>
            <Button
              btnSize="xsmall"
              btnStyle="secondary_border"
              text="copy"
              @onClickBtn="viewModel.onClickCopy(detailData.phone,'휴대전화')"/>
            </div>
          </td>
        </tr>
        <tr>
          <th>지갑 퍼핀머니 잔액</th>
          <td>
            <span class="txt_tbl">{{ detailData.money | convertNumberToComma(false,'0원','원') }}</span>
          </td>
          <th>지갑 포인트 잔액</th>
          <td>
            <span class="txt_tbl">{{ detailData.point | convertNumberToComma(false,'0원','원') }}</span>
          </td>
        </tr>
        <tr>
          <th>저금통 금액</th>
          <td v-if="detailData.savings?.id">
            <div class="group_form">
              <span class="txt_tbl item_form_fill">{{ detailData.savings?.amount | convertNumberToComma(false,'0원','원') }}</span>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text="저금통 강제해지"
                @onClickBtn="viewModel.onClickDiscardSavings()"/>
            </div>
          </td>
          <td v-else>
            <span class="txt_tbl tc_default">생성된 저금통이 없습니다</span>
          </td>
          <template v-if="detailData.familyPermission != 'LEADER' && detailData.familyRole == 'CHILD'">
            <th>리더에게 머니 반환</th>
            <td v-if="detailData.money > 0">
              <div class="group_form">
                <Input
                  placeholder="반환할 금액(미입력시 전액 반환)"
                  valueType="numberComma"
                  :isBlockUnValueType="true"
                  :value.sync="viewModel.refund_to_leader_amount"/>
                <Button
                  btnSize="small"
                  btnStyle="primary_border"
                  :text="`${!viewModel.refund_to_leader_amount || viewModel.refund_to_leader_amount === '0' ? '전액 ':''}반환하기`"
                  @onClickBtn="viewModel.onClickRefundToLeader()"/>
              </div>
              <p class="desc_info">💡 0이나 빈 값 입력시 전액이 반환됩니다.</p>
              <p class="desc_info">💡 퍼핀머니 잔액과 같거나 적은 값을 입력해주세요</p>
            </td>
            <td v-else>
              <span class="txt_tbl tc_default">반환할 퍼핀머니가 없습니다</span>
            </td>
          </template>
          <td colspan="2" v-else></td>
        </tr>
      </template>
    </TableView>
    <TableView
       v-if="detailData.cardApplication || detailData.card">
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
        <col style="width:160px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">카드 취소/해지</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>카드 취소</th>
          <td>
            <Button
              :disabled="!detailData.cardCancelable"
              btnSize="small"
              btnStyle="fourthly_border"
              text="카드 취소"
              @onClickBtn="viewModel.onClickCardCancel()"/>
          </td>
          <th>카드 해지</th>
          <td>
            <Button
              :disabled="!detailData.card"
              btnSize="small"
              btnStyle="fourthly_border"
              text="카드 해지"
              @onClickBtn="viewModel.onClickCardUnregister()"/>
          </td>
        </tr>
        <tr v-if="detailData.card">
          <th>카드 정지/정지해제</th>
          <td colspan="3">
            <div class="group_form">
              <Radio
                v-if="detailData.card.status === 'REGISTERED'"
                :dataList="'cs_card_suspend_type' | getSelectDataList"
                :value.sync="viewModel.isCardSuspendReason" />
              <p
                v-if="
                  detailData.card.status != 'REGISTERED' &&
                    detailData.card.status != 'SUSPENDED'
                "
                class="desc_info">
                💡 카드 정지는 '카드 등록' 상태에서 카드 정지 해제는 '카드 정지'
                상태에서만 가능합니다.
              </p>
            </div>
            <div class="group_form">
              <Button
                v-if="detailData.card.status != 'SUSPENDED'"
                btnSize="small"
                btnStyle="fourthly_border"
                text="카드 정지"
                :disabled="detailData.card.status != 'REGISTERED'"
                @onClickBtn="viewModel.onClickCardSuspend()" />
              <Button
                v-if="detailData.card.status === 'SUSPENDED'"
                btnSize="small"
                btnStyle="fourthly_border"
                text="카드 정지해제"
                @onClickBtn="viewModel.onClickCardSuspendCancel()" />
            </div>
          </td>
        </tr>
        <tr>
          <th>해지된 카드/신청서<br>상태변경</th>
          <td colspan="3">
            <Radio
              :dataList="'cs_card_update_free_cancel_reason' | getSelectDataList"
              :value.sync="viewModel.cardUpdateFreeCancelReason"/>
            <Button
              btnSize="small"
              btnStyle="primary_border"
              text="무료 재발급 상태로 변경"
              @onClickBtn="viewModel.onClickCardUpdateFreeCancel()"/>
            <Input
              v-if="viewModel.cardUpdateFreeCancelReason === 'ETC'"
              placeholder="기타사유(20자이내)"
              maxWidth="360px"
              :isCount="true"
              :maxLength="20"
              :value.sync="viewModel.cardUpdateFreeCancelEtcReason"/>
            <p class="desc_info">💡 해지된 카드를 임의로 취소상태로 변경하여 무료로 재발급할 수 있도록 합니다</p>
          </td>
        </tr>
        <tr>
          <th>반송배치로 온 폐기된 카드를 취소로 변경</th>
          <td colspan="3">
            <Button
              btnSize="small"
              btnStyle="primary_border"
              text="취소로 변경"
              @onClickBtn="viewModel.onClickCardDiscardCancel()"/>
              <p class="desc_info">💡 KB에서 반송 배치로 온 폐기된 카드와 신청서를 취소로 변경</p>
          </td>
        </tr>
      </template>
    </TableView>
    <!-- 카드 신청서 -->
    <Fold
       v-if="detailData.cardApplication"
      title="✍️ 카드 신청서"
      :currentFold="true">
      <template v-slot:body_fold>
        <TableView>
          <template v-slot:tbl_colgroup>
            <col style="width:160px"/>
            <col/>
            <col style="width:160px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>카드번호</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.cardNumber | emptyValue('','-') }}</span>
              </td>
              <th>카드 디자인</th>
              <td>
                  <div
                    v-if="detailData.cardApplication.cardDesignType"
                    class="img_card">
                    <img class="img_g" :src="`/assets/images/cs_user_byuserdata/card_design_${detailData.cardApplication.cardDesignType}.png`" alt="카드이미지"/>
                  </div>
                  <span class="txt_tbl">{{ detailData.cardApplication.cardDesignType | convertIdToText('cs_card_design_type') }}</span>
                  <!-- <Button
                    v-if="detailData.cardApplication.cardDesignType"
                    class="area_right"
                    :disabled="detailData.cardApplication.status != 'CONFIRM'"
                    btnSize="small"
                    btnStyle="secondary_border"
                    :text="viewModel.isCardDesignChange ? '디자인 변경취소':'디자인 변경'"
                    @onClickBtn="viewModel.onClickCardDesignChange()"/> -->
                <!-- <div
                  v-if="viewModel.isCardDesignChange"
                  class="group_form"
                  style="margin-top:16px">
                  <Selectbox
                    :dataList="viewModel.cardDesignDataList"
                    thumnailKey="cardDesignImg"
                    :thumnailSize="['auto' , '28px']"
                    :value.sync="viewModel.cardDesignChangedType" />
                  <Button
                    :disabled="!viewModel.cardDesignChangedType"
                    btnSize="small"
                    btnStyle="primary_border"
                    text="변경하기"
                    @onClickBtn="viewModel.onClickCardDesignChangeComplete()"/>
                </div> -->
              </td>
            </tr>
            <tr>
              <th>교통카드 타입</th>
              <td colspan="3">
                {{ detailData.cardApplication.trafficCardType | convertIdToText('card_traffic_type') }}
              </td>
            </tr>
            <tr>
              <th>ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.id }}</span>
              </td>
              <th>uid</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.uid }}</span>
              </td>
            </tr>
            <tr>
              <th>user ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.userId }}</span>
              </td>
              <th>부모 ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.parentId }}</span>
              </td>
            </tr>
            <tr>
              <th>카드 배송 받는 날</th>
              <td colspan="3">
                <span class="txt_tbl">{{ detailData.cardApplication.displayExpectedDate || '-' }}</span>
              </td>
            </tr>
            <tr>
              <th>카드 배송 받는 주소</th>
              <td colspan="3">
                <span class="txt_tbl">{{ addressFull }}</span>
              </td>
            </tr>
            <tr>
              <th>카드 상태</th>
              <td colspan="3">
                <span class="txt_tbl">{{ detailData.cardApplication.status }} ({{ detailData.cardApplication.status | convertIdToText('card_apply_status') }})</span>
                <StatusSelect
                  :dataList="'card_apply_status' | getSelectDataList"
                  :status="detailData.cardApplication.status"/>
              </td>
            </tr>
            <tr>
              <th>신청일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.applicationAt | convertDateFormat }}</span>
              </td>
              <th>신청서 전송일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.sendAt | convertDateFormat }}</span>
              </td>
            </tr>
            <tr>
              <th>상태 업데이트 일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.updatedAt | convertDateFormat }}</span>
              </td>
              <th>취소 일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.cardApplication.canceledAt | convertDateFormat }}</span>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 카드 신청서 내역 -->
    <Fold
      v-if="detailData.cardApplication"
      title="📝 카드 신청서 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickCardApplications()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.CARD_APPLICATIONS.boardData"
          :searchParams.sync="viewModel.boardData.CARD_APPLICATIONS.searchParams"
          :dataList="viewModel.boardData.CARD_APPLICATIONS.dataList"
          :paginationData="viewModel.boardData.CARD_APPLICATIONS.paginationData"
          :emptyDesc="viewModel.boardData.CARD_APPLICATIONS.searched && !viewModel.boardData.CARD_APPLICATIONS.hasData ? '카드 신청서 내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchCardApplications()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.CARD_APPLICATIONS.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchCardApplications()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 카드 배송 정보 -->
    <Fold
      v-if="detailData.cardApplication"
      title="📦 카드 배송 정보"
      :currentFold="true"
      @onClickFold="viewModel.onClickDeliveryData()">
      <template v-slot:body_fold>
        <div class="area_overflow">
          <div class="area_right">
            <p class="desc_right">{{ viewModel.boardData.DELIVERY_STATUS.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchDeliveryData()">
              <IconSvg
                iconName="reset"
                :size="12"
                iconColor="#222"
                iconDarkColor="#fff"/>
            </Button>
          </div>
        </div>
        <ul v-if="viewModel.boardData.DELIVERY_STATUS.searched && viewModel.boardData.DELIVERY_STATUS.hasData" class="list_status">
          <li
            v-for="(statusItem, index) in viewModel.boardData.DELIVERY_STATUS.dataList"
            :key="`deliveryStatus${index}`"
            :class="{
              'item_latest' : index === 0,
              'item_first' : index === viewModel.boardData.DELIVERY_STATUS.dataList.length-1,
            }">
            <div class="area_left">
              <strong class="txt_status">{{ statusItem.quickProgressStatus }}</strong>
              <span class="txt_date">{{ statusItem.quickProgressDate }} {{ statusItem.quickProgressTime }}</span>
            </div>
            <div class="area_overflow">
              <div v-if="statusItem.quickContent || statusItem.quickNote" class="box_desc">
                <p v-if="statusItem.quickContent">{{ statusItem.quickContent }}</p>
                <p v-if="statusItem.quickNote">{{ statusItem.quickNote }}</p>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="area_empty">
          <p class="desc_empty">{{ viewModel.boardData.DELIVERY_STATUS.searched && !viewModel.boardData.DELIVERY_STATUS.hasData ? '카드 배송 정보가 없습니다.' : '조회중입니다' }}</p>
        </div>
      </template>
    </Fold>
    <!-- 퍼핀 카드 정보 -->
    <Fold
      v-if="detailData.card"
      title="💳 퍼핀 카드 정보"
      :currentFold="true">
      <template v-slot:body_fold>
        <TableView>
          <template v-slot:tbl_colgroup>
            <col style="width:160px"/>
            <col/>
            <col style="width:160px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>카드번호</th>
              <td colspan="3">
                <span class="txt_tbl">{{ detailData.card.cardNumber | emptyValue('','-') }}</span>
              </td>
            </tr>
            <tr>
              <th>ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.id }}</span>
              </td>
              <th>카드신청서 ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.cardApplicationId }}</span>
              </td>
            </tr>
            <tr>
              <th>user ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.userId }}</span>
              </td>
              <th>부모 ID</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.parentId }}</span>
              </td>
            </tr>
            <tr>
              <th>카드 상태</th>
              <td colspan="3">
                <span class="txt_tbl">{{ detailData.card.status }} ({{ detailData.card.status | convertIdToText('card_status') }})</span>
                <StatusSelect
                  :dataList="'card_status' | getSelectDataList"
                  :status="detailData.card.status"/>
              </td>
            </tr>
            <tr>
              <th>신청일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.createdAt | convertDateFormat }}</span>
              </td>
              <th>사용등록일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.registeredAt | convertDateFormat }}</span>
              </td>
            </tr>
            <tr>
              <th>상태 업데이트 일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.updatedAt | convertDateFormat }}</span>
              </td>
              <th>취소 일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.canceledAt | convertDateFormat }}</span>
              </td>
            </tr>
            <tr>
              <th>cvc 입력 실패</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.cvcFailCount | emptyValue('회','0회') }}</span>
              </td>
              <th>비밀번호 입력 실패</th>
              <td>
                <span class="txt_tbl">{{ detailData.card.passwordFailCount | emptyValue('회','0회') }}</span>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 카드 사용 내역 -->
    <Fold
      title="🧾 카드 사용 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickCardHistory()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.CARD_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.CARD_HISTORY.searchParams"
          :dataList="viewModel.boardData.CARD_HISTORY.dataList"
          :paginationData="viewModel.boardData.CARD_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.CARD_HISTORY.searched && !viewModel.boardData.CARD_HISTORY.hasData ? '카드 사용 내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchCardHistory()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.CARD_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchCardHistory()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">카드 사용 내역 증명서 발급</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:160px"/>
            <col/>
            <col style="width:126px"/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>발급기간</th>
              <td>
                <DateRangeQuick
                  :startValue.sync="viewModel.cardTransactionCertificate.start_date"
                  :endValue.sync="viewModel.cardTransactionCertificate.end_date"
                  :quickTypeData="[
                    { quickType: 'TODAY', customText: '오늘' },
                    { quickType: 'ONE_WEEK', customText: '1주일' },
                    { quickType: 'ONE_MONTH', customText: '1개월' },
                    { quickType: 'THREE_MONTH', customText: '3개월' },
                    { quickType: 'SIX_MONTH', customText: '6개월' },
                    { quickType: 'ONE_YEAR', customText: '1년' },
                    { quickType: 'THIS_WEEK', customText: '이번주' },
                    { quickType: 'THIS_MONTH', customText: '이번달' },
                    { quickType: 'LAST_MONTH', customText: '지난달' },
                  ]"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="primary_border"
                  text="발급하기"
                  :disabled="!viewModel.cardTransactionCertificate.start_date || !viewModel.cardTransactionCertificate.end_date"
                  @onClickBtn="viewModel.onClickCardTransactionCertificate()">
                </Button>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 은행(쿠콘) 입출금 내역 -->
    <Fold
      title="🏦 은행(쿠콘) 입출금 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickTransData()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.TRANS_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.TRANS_HISTORY.searchParams"
          :dataList="viewModel.boardData.TRANS_HISTORY.dataList"
          :paginationData="viewModel.boardData.TRANS_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.TRANS_HISTORY.searched && !viewModel.boardData.TRANS_HISTORY.hasData ? '입출금 내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchTransData()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.TRANS_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchTransData()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 가상계좌 발급내역 -->
    <Fold title="👻 가상계좌 발급내역" :currentFold="true">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.virtualAccountsBoardData.boardData"
          :searchParams.sync="viewModel.virtualAccountsBoardData.searchParams"
          :dataList="viewModel.detailData.virtualAccounts"
          :paginationData="viewModel.virtualAccountsBoardData.paginationData"
          emptyDesc="발급내역이 없습니다"
        >
          <template v-slot:headRight>
            <Button
              v-if="viewModel.detailData.virtualAccounts[0] && viewModel.detailData.virtualAccounts[0].status === 'REGISTERED'"
              btnSize="small"
              btnStyle="fourthly_border"
              text="가상계좌 차단"
              @onClickBtn="viewModel.onClickVirtualAccountBlock()">
            </Button>
            <Button
              v-if="viewModel.detailData.virtualAccounts[0] && viewModel.detailData.virtualAccounts[0].status === 'BLOCKED'"
              btnSize="small"
              btnStyle="secondary_border"
              text="가상계좌 차단 해제"
              @onClickBtn="viewModel.onClickVirtualAccountUnBlock()">
            </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 가상계좌 거래내역 -->
    <Fold
      title="♻️ 가상계좌 거래내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickVirtualAccountTransData()">
      <template v-slot:body_fold>
        <Board
          :boardData="
            viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.boardData
          "
          :searchParams.sync="viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.searchParams"
          :dataList="viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.dataList"
          :paginationData="viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.paginationData"
          :emptyDesc="viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.searched && !viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.hasData ? '거래내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchVirtualAccountTransData()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchVirtualAccountTransData()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 바코드 결제내역 -->
    <Fold
      title="🎹 바코드 결제내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickBarcodeTransationHistory()">
      <template v-slot:body_fold>
        <Board
          :boardData="
            viewModel.boardData.BARCODE_TRANSACTION_HISTORY.boardData
          "
          :searchParams.sync="viewModel.boardData.BARCODE_TRANSACTION_HISTORY.searchParams"
          :dataList="viewModel.boardData.BARCODE_TRANSACTION_HISTORY.dataList"
          :paginationData="viewModel.boardData.BARCODE_TRANSACTION_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.BARCODE_TRANSACTION_HISTORY.searched && !viewModel.boardData.BARCODE_TRANSACTION_HISTORY.hasData ? '거래내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchBarcodeTransationHistory()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.BARCODE_TRANSACTION_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchVirtualAccountTransData()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 지갑 내역 -->
    <Fold
      title="👛 지갑 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickWalletHistoryData()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.WALLET_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.WALLET_HISTORY.searchParams"
          :dataList="viewModel.boardData.WALLET_HISTORY.dataList"
          :paginationData="viewModel.boardData.WALLET_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.WALLET_HISTORY.searched && !viewModel.boardData.WALLET_HISTORY.hasData ? '지갑 내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchWalletHistory()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.WALLET_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchWalletHistory()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
        <TableView>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">지갑 내역 증명서 발급</strong>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:160px"/>
            <col/>
            <col style="width:126px"/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>발급기간</th>
              <td>
                <DateRangeQuick
                  :startValue.sync="viewModel.walletTransactionCertificate.start_date"
                  :endValue.sync="viewModel.walletTransactionCertificate.end_date"
                  :quickTypeData="[
                    { quickType: 'TODAY', customText: '오늘' },
                    { quickType: 'ONE_WEEK', customText: '1주일' },
                    { quickType: 'ONE_MONTH', customText: '1개월' },
                    { quickType: 'THREE_MONTH', customText: '3개월' },
                    { quickType: 'SIX_MONTH', customText: '6개월' },
                    { quickType: 'ONE_YEAR', customText: '1년' },
                    { quickType: 'THIS_WEEK', customText: '이번주' },
                    { quickType: 'THIS_MONTH', customText: '이번달' },
                    { quickType: 'LAST_MONTH', customText: '지난달' },
                  ]"/>
              </td>
              <td>
                <Button
                  btnSize="medium"
                  btnStyle="primary_border"
                  text="발급하기"
                  :disabled="!viewModel.walletTransactionCertificate.start_date || !viewModel.walletTransactionCertificate.end_date"
                  @onClickBtn="viewModel.onClickWalletTransactionCertificate()">
                </Button>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 퍼핀머니 내역 -->
    <Fold
      title="💵 퍼핀머니 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickMoneyHistoryData()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.MONEY_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.MONEY_HISTORY.searchParams"
          :dataList="viewModel.boardData.MONEY_HISTORY.dataList"
          :paginationData="viewModel.boardData.MONEY_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.MONEY_HISTORY.searched && !viewModel.boardData.MONEY_HISTORY.hasData ? '퍼핀머니 내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchMoneyHistory()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.MONEY_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchMoneyHistory()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 포인트 내역 -->
    <Fold
      title="💎 포인트 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickPointHistoryData()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.POINT_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.POINT_HISTORY.searchParams"
          :dataList="viewModel.boardData.POINT_HISTORY.dataList"
          :paginationData="viewModel.boardData.POINT_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.POINT_HISTORY.searched && !viewModel.boardData.POINT_HISTORY.hasData ? '포인트 내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchPointHistory()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.POINT_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchPointHistory()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 저금통 -->
    <Fold
       v-if="detailData.savings?.id"
      title="🐖 저금통"
      :currentFold="true">
      <template v-slot:body_fold>
        <TableView>
          <template v-slot:tbl_colgroup>
            <col style="width:160px"/>
            <col/>
            <col style="width:160px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>저금통 이름</th>
              <td colspan="3">
                <span class="txt_tbl">{{ detailData.savings.title | emptyValue('','-') }}</span>
              </td>
            </tr>
            <tr>
              <th>모은 금액</th>
              <td>
                <span class="txt_tbl">{{ detailData.savings.amount | convertNumberToComma(false,'0원','원') }}</span>
              </td>
              <th>목표 금액</th>
              <td>
                <span class="txt_tbl">{{ detailData.savings.goal | convertNumberToComma(false,'0원','원') }}</span>
              </td>
            </tr>
            <tr>
              <th>생성일시</th>
              <td>
                <span class="txt_tbl">{{ detailData.savings.createdAt | convertDateFormat }}</span>
              </td>
              <th>상태</th>
              <td>
                <span class="txt_tbl">{{ detailData.savings.status | emptyValue('','-') }}</span>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 송금/결제 한도 -->
    <Fold
      title="⌛️ 송금/결제 한도"
      :currentFold="true"
      @onClickFold="viewModel.onClickLimitFold()">
      <template v-slot:body_fold>
        <TableView class="tbl_transaction_limit">
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">결제한도 제한 : 
                <span :class="$options.filters.convertIdToColor(viewModel.boardData.TRANSACTION_LIMIT.data.status, 'cs_transaction_limit_status', 'tc')">{{ viewModel.boardData.TRANSACTION_LIMIT.data.status | convertIdToText('cs_transaction_limit_status') }}</span>
              </strong>
            </div>
            <div class="area_right">
              <p class="desc_right">{{ viewModel.boardData.TRANSACTION_LIMIT.responseDate }}</p>
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="새로고침"
                @onClickBtn="viewModel.onSearchTransactionLimit()">
              <IconSvg
                iconName="reset"
                :size="12"
                iconColor="#222"
                iconDarkColor="#fff"/>
            </Button>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:180px"/>
            <col/>
            <col/>
          </template>
          <template v-slot:tbl_head>
            <th></th>
            <th>1일</th>
            <th>1달</th>
          </template>
          <template v-slot:tbl_body>
            <tr :class="{tr_selected : viewModel.boardData.TRANSACTION_LIMIT.data.status != 'REGISTERED'}">
              <th>기본</th>
              <td>
                {{ 500000 | convertNumberToComma(false,'0원','원') }}
                ({{ 500000 | getMoneyToKorean('0원','원') }})
              </td>
              <td>
                {{ 2000000 | convertNumberToComma(false,'0원','원') }}
                ({{ 2000000 | getMoneyToKorean('0원','원') }})
              </td>
            </tr>
            <tr v-if="viewModel.boardData.TRANSACTION_LIMIT.data.status != 'NONE'" :class="{tr_selected : viewModel.boardData.TRANSACTION_LIMIT.data.status === 'REGISTERED'}">
              <th>사용자설정</th>
              <td>
                {{ viewModel.boardData.TRANSACTION_LIMIT.data.setupDailyLimit | convertNumberToComma(false,'0원','원') }}
                ({{ viewModel.boardData.TRANSACTION_LIMIT.data.setupDailyLimit | getMoneyToKorean('0원','원') }})
              </td>
              <td>
                {{ viewModel.boardData.TRANSACTION_LIMIT.data.setupMonthlyLimit | convertNumberToComma(false,'0원','원') }}
                ({{ viewModel.boardData.TRANSACTION_LIMIT.data.setupMonthlyLimit | getMoneyToKorean('0원','원') }})
              </td>
            </tr>
          </template>
        </TableView>
        <TableView v-if="viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily" class="tbl_transaction_limit_possible">
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">남은 거래가능 금액 조회</strong>
            </div>
            <div class="area_right">
              <p class="desc_right">{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.responseDate }}</p>
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="새로고침"
                @onClickBtn="viewModel.onSearchTransactionLimitPossible()">
              <IconSvg
                iconName="reset"
                :size="12"
                iconColor="#222"
                iconDarkColor="#fff"/>
            </Button>
            </div>
          </template>
          <template v-slot:tbl_colgroup>
            <col style="width:180px"/>
            <col/>
            <col/>
          </template>
          <template v-slot:tbl_head>
            <th></th>
            <th>1일</th>
            <th>1달</th>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>한도</th>
              <td>
                {{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.limit_amount | convertNumberToComma(false,'0원','원') }}
              </td>
              <td>
                {{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.limit_amount | convertNumberToComma(false,'0원','원') }}
              </td>
            </tr>
            <tr><td colspan="3"></td></tr>
            <tr>
              <th>출금이체 금액 총 합</th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.bank_account_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.bank_account_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>바코드 결제 금액 총 합</th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.barcode_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.barcode_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>카드 결제 금액 총 합</th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.card_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.card_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>페이 결제 금액</th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.payment_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.payment_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>머니 송금 금액</th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.send_money_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.send_money_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>포인트 송금 금액</th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.send_point_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.send_point_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>총 거래 금액<span class="txt_info">(= 출금이체 + 바코드 결제 + 카드 결제 + 페이 결제 + 머니 송금 + 포인트 송금)</span></th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.total_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.total_transaction_amount | convertNumberToComma(false,'0원','원') }}</td>
            </tr>
            <tr>
              <th>남은 거래가능 금액<span class="txt_info">(= 한도 - 총 거래 금액)</span></th>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.remain_transaction_amount | convertNumberToComma(false,'0원','원') }}
                ({{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.daily.remain_transaction_amount | getMoneyToKorean('0원','원') }})</td>
              <td>{{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.remain_transaction_amount | convertNumberToComma(false,'0원','원') }}
                ({{ viewModel.boardData.TRANSACTION_LIMIT_POSSIBLE.data.monthly.remain_transaction_amount | getMoneyToKorean('0원','원') }})</td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 소득 공제 -->
    <Fold
      title="💸 소득공제"
      :currentFold="true"
      @onClickFold="viewModel.onClickDeductionFold()">
      <template v-slot:body_fold>
        <Board
          :boardData="viewModel.boardData.DEDUCTION_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.DEDUCTION_HISTORY.searchParams"
          :dataList="viewModel.boardData.DEDUCTION_HISTORY.dataList"
          :paginationData="viewModel.boardData.DEDUCTION_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.DEDUCTION_HISTORY.searched && !viewModel.boardData.DEDUCTION_HISTORY.hasData ? '내역이 없습니다.' : '조회중입니다'"
          @onSearch="viewModel.onSearchPointHistory()">
          <template v-slot:headRight>
            <p class="desc_right">{{ viewModel.boardData.DEDUCTION_HISTORY.responseDate }}</p>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="새로고침"
              @onClickBtn="viewModel.onSearchDeductionHistory()">
            <IconSvg
              iconName="reset"
              :size="12"
              iconColor="#222"
              iconDarkColor="#fff"/>
          </Button>
          </template>
        </Board>
      </template>
    </Fold>
    <!-- 멤버십 구매 내역 -->
    <Fold
      v-if="detailData.memberships.length > 0"
      title="🎖 멤버십 구매 내역"
      :currentFold="true"
      @onClickFold="viewModel.onClickMembershipFold()">
      <template v-slot:body_fold>
        <Board
          id="membershipPaymentBoard"
          :boardData="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.boardData"
          :searchParams.sync="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.searchParams"
          :dataList="detailData.memberships"
          :paginationData="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.paginationData"
          :emptyDesc="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.searched && !viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.hasData ? '멤버십 구매 내역이 없습니다.' : '조회중입니다'"
          :checkboxOnlyOne.sync="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.checkboxOnlyOne"
          :selectedDataList.sync="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList">
          <template v-slot:headRight>
            <Button
              v-if="!viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.isShowRowCheck"
              btnSize="small"
              btnStyle="secondary_border"
              text="복사 데이터 선택"
              @onClickBtn="viewModel.onClickShowRowCheck()">
            </Button>
            <Button
              v-else
              btnSize="small"
              btnStyle="secondary_border"
              text="데이터 복사"
              :disabled="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList.length < 1"
              @onClickBtn="viewModel.onClickCopyTable()">
            </Button>
         </template>
        </Board>
        <TableView v-if="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.isMembershipPaymentCancelView">
          <template v-slot:tbl_colgroup>
            <col style="width:140px"/>
            <col/>
          </template>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">멤버십 구매취소</strong>
            </div>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>멤버십 구매취소 환불금액</th>
              <td>
                <div class="group_form">
                  <Input
                    placeholder="환불금액 기록용"
                    valueType="numberComma"
                    :isBlockUnValueType="true"
                    :value.sync="viewModel.boardData.MEMBERSHIP_PAYMENT_HISTORY.cancelModel.cancel_amount"/>
                  <Button
                    btnSize="small"
                    btnStyle="fourthly_border"
                    text="구매취소"
                    @onClickBtn="viewModel.onClickMembershipCancelCompelete()"/>
                </div>
                <p class="desc_info">💡 실제 환불되는 금액이 아닌 기록용입니다</p>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- 블랙리스트 관리 -->
    <Fold
      title="🥷 블랙리스트 관리"
      :currentFold="true"
      @onClickFold="viewModel.onClickBlockFold()">
      <template v-slot:body_fold>
        <TableView>
          <template v-slot:tbl_colgroup>
            <col style="width:140px"/>
            <col/>
            <col style="width:140px"/>
            <col/>
          </template>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">앱 사용 차단</strong>
            </div>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>상태</th>
              <td colspan="3">
                <span class="txt_tbl">
                  <Badge
                    :text="viewModel.blockData.NOT_ALLOW_USE_APP.status | convertIdToText('cs_block_status')"
                    :badgeStyle="viewModel.blockData.NOT_ALLOW_USE_APP.status | convertIdToColor('cs_block_status')"
                    :badgeFill="true" />
                </span>
              </td>
            </tr>
            <template v-if="viewModel.blockData.NOT_ALLOW_USE_APP.data && viewModel.blockData.NOT_ALLOW_USE_APP.data.blocked">
              <tr>
                <th>차단 기한</th>
                <td>{{viewModel.blockData.NOT_ALLOW_USE_APP.data.start_at | convertDateFormat()}} ~ {{viewModel.blockData.NOT_ALLOW_USE_APP.data.end_at | convertDateFormat()}}</td>
                <th>차단 등록자</th>
                <td>{{ viewModel.blockData.NOT_ALLOW_USE_APP.data.registered_by }}</td>
              </tr>
              <tr>
                <th>사유</th>
                <td colspan="3">
                  <span class="txt_tbl txt_break_all">{{ viewModel.blockData.NOT_ALLOW_USE_APP.data.registered_reason }}</span>
                </td>
              </tr>
              <tr>
                <th>차단 해제</th>
                <td colspan="3">
                  <Textarea
                    placeholder="사용 차단 해제 사유 (필수 아님)"
                    :showLine="3"
                    :value.sync="viewModel.blockData.NOT_ALLOW_USE_APP.reason"
                    :max="200"
                    :isCount="true"/>
                  <Button
                    btnSize="small"
                    btnStyle="secondary_border"
                    text="사용 차단 해제하기"
                    @onClickBtn="viewModel.onClickBlockDelete(viewModel.blockData.NOT_ALLOW_USE_APP.data.uid, 'NOT_ALLOW_USE_APP')"/>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th>차단 등록</th>
                <td colspan="3">
                  <Textarea
                    placeholder="사용 차단 사유 (필수 아님)"
                    :showLine="3"
                    :value.sync="viewModel.blockData.NOT_ALLOW_USE_APP.reason"
                    :max="200"
                    :isCount="true"/>
                  <Button
                    btnSize="small"
                    btnStyle="fourthly_border"
                    text="사용 차단하기"
                    @onClickBtn="viewModel.onClickBlockAppUse()"/>
                </td>
              </tr>
            </template>
          </template>
        </TableView>
        <TableView>
          <template v-slot:tbl_colgroup>
            <col style="width:140px"/>
            <col/>
            <col style="width:140px"/>
            <col/>
          </template>
          <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">가입 차단</strong>
            </div>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <th>상태</th>
              <td colspan="3">
                <span class="txt_tbl">
                  <Badge
                    :text="viewModel.blockData.USER_JOIN_BLOCK.status | convertIdToText('cs_block_status')"
                    :badgeStyle="viewModel.blockData.USER_JOIN_BLOCK.status | convertIdToColor('cs_block_status')"
                    :badgeFill="true" />
                </span>
              </td>
            </tr>
            <template v-if="viewModel.blockData.USER_JOIN_BLOCK.data && viewModel.blockData.USER_JOIN_BLOCK.data.blocked">
              <tr>
                <th>차단 기한</th>
                <td>{{viewModel.blockData.USER_JOIN_BLOCK.data.start_at | convertDateFormat()}} ~ {{viewModel.blockData.USER_JOIN_BLOCK.data.end_at | convertDateFormat()}}</td>
                <th>차단 등록자</th>
                <td>{{ viewModel.blockData.USER_JOIN_BLOCK.data.registered_by }}</td>
              </tr>
              <tr>
                <th>사유</th>
                <td colspan="3">
                  <span class="txt_tbl txt_break_all">{{ viewModel.blockData.USER_JOIN_BLOCK.data.registered_reason }}</span>
                </td>
              </tr>
              <tr>
                <th>차단 해제</th>
                <td colspan="3">
                  <Textarea
                    placeholder="가입 차단 해제 사유 (필수 아님)"
                    :showLine="3"
                    :value.sync="viewModel.blockData.USER_JOIN_BLOCK.reason"
                    :max="200"
                    :isCount="true"/>
                  <Button
                    btnSize="small"
                    btnStyle="secondary_border"
                    text="가입 차단 해제하기"
                    @onClickBtn="viewModel.onClickBlockDelete(viewModel.blockData.USER_JOIN_BLOCK.data.uid, 'USER_JOIN_BLOCK')"/>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th>차단 등록</th>
                <td colspan="3">
                  <Textarea
                    placeholder="가입 차단 사유 (필수 아님)"
                    :showLine="3"
                    :value.sync="viewModel.blockData.USER_JOIN_BLOCK.reason"
                    :max="200"
                    :isCount="true"/>
                  <Button
                    btnSize="small"
                    btnStyle="fourthly_border"
                    text="가입 차단하기"
                    @onClickBtn="viewModel.onClickBlockRegister()"/>
                </td>
              </tr>
            </template>
          </template>
        </TableView>
      </template>
    </Fold>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import Fold from '@lemontree-ai/lemontree-admin-common-front/components/common/fold/Fold';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
// import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import DateRangeQuick from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateRangeQuick';
import StatusSelect from '@lemontree-ai/lemontree-admin-common-front/components/common/statusSelect/StatusSelect';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Board from '@/adminPackage/components/common/board/Board';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
// viewModel
import CsUserByUserDataDetailViewModel from '@/views/cs/user/byuserdata/viewModel/CsUserByUserDataDetailViewModel'

export default {
  name:'CsUserByUserDataDetailPopup',
  props:{
    detailData: Object
  },
  components:{
    Popup,
    Fold,
    TableView,
    // Selectbox,
    DateRangeQuick,
    StatusSelect,
    Badge,
    Button,
    IconSvg,
    Board,
    Input,
    Radio,
    Textarea
  },
  beforeMount(){
    this.viewModel.setDetailData(this.detailData);
    // onSearchList
    const onSearchList = (popupUpdateData) => { this.$emit('onSearchList',popupUpdateData); }
    this.viewModel.onSearchList = onSearchList;

    document.title = this.detailData.name;
  },
  computed:{
    addressFull(){
      const cardApplication = this.detailData?.cardApplication;

      if(!cardApplication) return null;

      const { address, detailAddress } = cardApplication;

      if(!detailAddress) return address;

      return address + ' ' + detailAddress;
    },
  },
  watch:{
    detailData(){
      this.viewModel.setDetailData(this.detailData);
    }
  },
  data(){
    return{
      viewModel: new CsUserByUserDataDetailViewModel(),
    }
  },
  methods:{
    onClickClose(){
      document.title = 'Firfin Finance Admin';
      this.$emit('onClickClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.layer_body .tbl_view:first-child .tbl_top{margin-top:0}

.img_card{float:left;height:38px;margin-right:16px;border-radius:2px;box-shadow:0px 0px 7px 2px rgba(0, 0, 0, .2)}
.img_card .img_g{width:auto;height:100%}

.list_status li{position:relative;display:block;overflow:hidden;height:50px;padding:0 0 26px 40px}
.list_status li.item_first{padding-bottom:10px}
.list_status li:before{position:absolute;left:6.5px;top:0;bottom:0;width:3px;height:100%;background-color:$DEFAULT_BORDER;content:''}
.list_status li.item_first:before{height:14px}
.list_status li.item_latest:before{top:14px}
.list_status li:after{position:absolute;left:0;top:14px;width:16px;height:16px;border-radius:100%;border:4px solid $DEFAULT_BORDER;background-color:$DEFAULT_BG;box-sizing:border-box;content:''}
.list_status li.item_latest:after{border-color:$PRIMARY_COLOR}
.list_status li .area_left{width:160px;padding-top:10px}
.list_status li .txt_status{display:block;font-weight:700;font-size:16px;line-height:18px;color:$CONTENT_TEXT}
.list_status li .txt_date{display:block;margin-top:4px;font-size:14px;line-height:16px;color:$CONTENT_SUB_TEXT}
.list_status li .box_desc{padding:6px 12px;font-size:14px;line-height:18px;border:1px solid $DEFAULT_BORDER;border-radius:4px;color:$CONTENT_TEXT}

.desc_right{display:inline-block;margin:16px 8px 6px 0;font-size:14px;line-height:16px;color:$CONTENT_SUB_TEXT}

.tbl_transaction_limit_possible tbody th{padding:7px 20px}
.tbl_transaction_limit_possible tbody td{padding:0 20px}

@media (prefers-color-scheme: dark) {
.list_status li:before{background-color:$DEFAULT_BORDER_DARK}
.list_status li:after{border-color:$DEFAULT_BORDER_DARK;background-color:$TITLE_BG_DARK}
.list_status li .txt_status{color:$CONTENT_TEXT_DARK}
.list_status li .txt_date{color:$CONTENT_SUB_TEXT_DARK}
.list_status li .box_desc{border-color:$DEFAULT_BORDER_DARK;color:$CONTENT_TEXT_DARK}
}
</style>