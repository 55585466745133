var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWithLayout',{attrs:{"isBodyFull":true},scopedSlots:_vm._u([{key:"popup",fn:function(){return [(_vm.viewModel.groupCategoryDrawData.CATEGORY.isRegisterPopup)?_c('CategoryRegisterPopup',{attrs:{"mappingViewModel":_vm.viewModel},on:{"onClickPopupClose":function($event){return _vm.viewModel.onClickPopupClose('CATEGORY')},"onClickPopupComplete":data => _vm.viewModel.onClickPopupComplete('CATEGORY', data)}}):_vm._e(),(_vm.viewModel.groupCategoryDrawData.BRAND.isRegisterPopup)?_c('BrandRegisterPopup',{attrs:{"mappingViewModel":_vm.viewModel},on:{"onClickPopupClose":function($event){return _vm.viewModel.onClickPopupClose('BRAND')},"onClickPopupComplete":data => _vm.viewModel.onClickPopupComplete('BRAND', data)}}):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"wrap_flex"},[_c('div',{staticClass:"group_category"},[_c('GroupCategory',{attrs:{"groupCategoryData":_vm.viewModel.groupCategoryDrawData.CATEGORY,"dataList":_vm.viewModel.dataViewModel.CATEGORY.dataList,"value":_vm.viewModel.dataViewModel.CATEGORY.value,"rowIdValue":"uid"},on:{"update:groupCategoryData":function($event){return _vm.$set(_vm.viewModel.groupCategoryDrawData, "CATEGORY", $event)},"update:group-category-data":function($event){return _vm.$set(_vm.viewModel.groupCategoryDrawData, "CATEGORY", $event)},"update:dataList":function($event){return _vm.$set(_vm.viewModel.dataViewModel.CATEGORY, "dataList", $event)},"update:data-list":function($event){return _vm.$set(_vm.viewModel.dataViewModel.CATEGORY, "dataList", $event)},"update:value":function($event){return _vm.$set(_vm.viewModel.dataViewModel.CATEGORY, "value", $event)},"onClickAdd":categoryId => _vm.viewModel.onClickAdd(categoryId),"onClickCategoryItem":(categoryId, rowData) =>
            _vm.viewModel.onSelectCategoryItem(categoryId, rowData),"onModifyOrder":orderData => _vm.viewModel.onModifyOrder(orderData)},scopedSlots:_vm._u([{key:"item_category",fn:function(slotProps){return [_c('span',{staticClass:"txt_item"},[_c('IconSvg',{attrs:{"iconName":"drag","size":18,"iconColor":"#bbb"}})],1),_c('span',{staticClass:"txt_item"},[_c('span',{staticClass:"box_color",style:(`background-color:${_vm.$options.filters.convertIdToColor(
                  slotProps.itemData.status,
                  'barcode_status_hex',
                )}`)})]),_c('span',{staticClass:"txt_item img_item",style:(`background-color:${slotProps.itemData.color_code}`)},[_c('img',{staticClass:"img_thumnail",attrs:{"src":slotProps.itemData.icon_img_url,"alt":""}})]),_c('strong',{staticClass:"tit_item",staticStyle:{"width":"80px","margin":"0 14px 0 12px"}},[_vm._v(_vm._s(slotProps.itemData.category_name))]),_c('span',{staticClass:"tit_item fill_item"},[_vm._v(" "+_vm._s(slotProps.itemData.category_description)+" ")])]}}])}),_c('GroupCategory',{attrs:{"groupCategoryData":_vm.viewModel.groupCategoryDrawData.BRAND,"dataList":_vm.viewModel.dataViewModel.BRAND.dataList,"value":_vm.viewModel.dataViewModel.BRAND.value,"rowIdValue":"brand_code","upperData":{
          title: _vm.viewModel.groupCategoryDrawData.CATEGORY.title,
          value: _vm.viewModel.dataViewModel.CATEGORY.value,
        }},on:{"update:groupCategoryData":function($event){return _vm.$set(_vm.viewModel.groupCategoryDrawData, "BRAND", $event)},"update:group-category-data":function($event){return _vm.$set(_vm.viewModel.groupCategoryDrawData, "BRAND", $event)},"update:dataList":function($event){return _vm.$set(_vm.viewModel.dataViewModel.BRAND, "dataList", $event)},"update:data-list":function($event){return _vm.$set(_vm.viewModel.dataViewModel.BRAND, "dataList", $event)},"update:value":function($event){return _vm.$set(_vm.viewModel.dataViewModel.BRAND, "value", $event)},"onClickAdd":categoryId => _vm.viewModel.onClickAdd(categoryId),"onClickCategoryItem":(categoryId, rowData) =>
            _vm.viewModel.onSelectCategoryItem(categoryId, rowData),"onModifyOrder":orderData => _vm.viewModel.onModifyOrder(orderData)},scopedSlots:_vm._u([{key:"item_category",fn:function(slotProps){return [_c('span',{staticClass:"txt_item"},[_c('IconSvg',{attrs:{"iconName":"drag","size":18,"iconColor":"#bbb"}})],1),_c('span',{staticClass:"txt_item"},[_c('span',{staticClass:"box_color",style:(`background-color:${_vm.$options.filters.convertIdToColor(
                  slotProps.itemData.status,
                  'barcode_status_hex',
                )}`)})]),_c('strong',{staticClass:"txt_item img_item"},[_c('img',{staticClass:"img_thumnail",attrs:{"src":slotProps.itemData.brand_logo_img_url,"alt":""}})]),_c('strong',{staticClass:"tit_item fill_item"},[_vm._v(_vm._s(slotProps.itemData.brand_name))]),_c('span',{staticClass:"txt_item"},[_vm._v(_vm._s(_vm._f("convertIdToText")(slotProps.itemData.payment_category,'barcode_payment_category')))]),_c('span',{staticClass:"txt_item"},[_vm._v(_vm._s(slotProps.itemData.payback_percent)+"%")])]}}])})],1),_c('div',{staticClass:"area_preview"},_vm._l((_vm.viewModel.dataList),function(item){return _c('div',{key:item.uid,staticClass:"item_category"},[_c('strong',{staticClass:"tit_category",style:(`background-color: ${_vm.$options.filters.convertIdToColor(item.barcode_category_dto.color,'barcode_category_color_bg')};
          border-color: ${_vm.$options.filters.convertIdToColor(item.barcode_category_dto.color,'barcode_category_color_border')}`)},[_c('img',{staticClass:"img_category",attrs:{"src":item.barcode_category_dto.icon_img_url,"alt":""}}),_vm._v(" "+_vm._s(item.barcode_category_dto.category_description)+" ")]),_c('ul',{staticClass:"list_brand"},_vm._l((item.barcode_brand_dto_list),function(brandItem){return _c('li',{key:brandItem.uid},[_c('img',{staticClass:"img_brand",attrs:{"src":brandItem.brand_logo_img_url,"alt":""}}),_c('strong',{staticClass:"tit_brand"},[_vm._v(_vm._s(brandItem.brand_name))]),_c('p',{staticClass:"desc_brand"},[_vm._v(_vm._s(brandItem.payback_percent)+"% 적립")])])}),0)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }