<template>
  <PageWithLayout class="page_monitofing_aml">
    <BoxView title="KYC 연/월간 현황" :grid="50">
      <div>
        <div class="wrap_data">
          <DoughnutChart title="2024년" :chartData="kycYearChartData" />
          <ul class="list_data">
            <li>CDD: 44823</li>
            <li>EDD: 517</li>
            <li>보류(무기명 자녀): 35035</li>
          </ul>
        </div>
      </div>
      <div>
        <div class="wrap_data">
          <DoughnutChart title="11월" :chartData="kycMonthChartData" />
          <ul class="list_data">
            <li>CDD: 504</li>
            <li>EDD: 18</li>
            <li>보류(무기명 자녀): 820</li>
          </ul>
        </div>
      </div>
    </BoxView>
    <BoxView title="의심거래 발생현황" :grid="50">
      <BarChart title="주간" :chartData="strWeekChartData" />
      <BarChart title="일간" :chartData="strDayMonthChartData" />
    </BoxView>
    <BoxView title="KYC 주간 현황" :grid="50">
      <BarChart title="CDD" :chartData="kycWeekCDDChartData" />
      <BarChart title="EDD" :chartData="kycWeekEDDChartData" />
      <BarChart
        title="보류(무기명 자녀)"
        :chartData="kycWeekPanddingChartData"
      />
    </BoxView>
    <BoxView title="STR 보고 검토 대상" :grid="50">
      <TableList>
        <template v-slot:tbl_colgroup>
          <col style="width:94px" />
          <col style="width:60px" />
          <col style="width:90px" />
          <col style="width:70px" />
          <col style="width:90px" />
          <col style="width:200px" />
          <col />
        </template>
        <template v-slot:tbl_head>
          <th>일시</th>
          <th>이름</th>
          <th>생년월일</th>
          <th>카테고리</th>
          <th>페이 회원 ID</th>
          <th>퍼핀 회원 ID</th>
          <th>비고</th>
        </template>
        <template v-slot:tbl_body>
          <tbody>
            <tr>
              <td>
                <span class="txt_tbl">11/12 19:02</span>
              </td>
              <td>
                <span class="txt_tbl">정*뮬</span>
              </td>
              <td>
                <span class="txt_tbl">2013/03/13</span>
              </td>
              <td>
                <span class="txt_tbl">사기</span>
              </td>
              <td>
                <span class="txt_tbl">75974</span>
              </td>
              <td>
                <span class="txt_tbl">GPLf73EJ2H9cBxtHAOAMv</span>
              </td>
              <td>
                <span class="txt_tbl"></span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="txt_tbl">11/10 16:02</span>
              </td>
              <td>
                <span class="txt_tbl">김*우</span>
              </td>
              <td>
                <span class="txt_tbl">20110617</span>
              </td>
              <td>
                <span class="txt_tbl">사기</span>
              </td>
              <td>
                <span class="txt_tbl">10589</span>
              </td>
              <td>
                <span class="txt_tbl">iAyd4NROj_azTb8PeirYv</span>
              </td>
              <td>
                <span class="txt_tbl"></span>
              </td>
            </tr>
            <tr>
              <td><span class="txt_tbl">11/09 14:02</span></td>
              <td><span class="txt_tbl">정*성</span></td>
              <td><span class="txt_tbl">20130115</span></td>
              <td><span class="txt_tbl">가상계좌</span></td>
              <td><span class="txt_tbl">250706</span></td>
              <td><span class="txt_tbl">GPLf73EJzH9cBxtHAOAMv</span></td>
              <td><span class="txt_tbl"></span></td>
            </tr>
            <tr>
              <td><span class="txt_tbl">11/06 14:02</span></td>
              <td><span class="txt_tbl">국*호</span></td>
              <td><span class="txt_tbl">20081230</span></td>
              <td><span class="txt_tbl">이체</span></td>
              <td><span class="txt_tbl">282498</span></td>
              <td><span class="txt_tbl">mVaeolHaCEgUdt-tH_tzr</span></td>
              <td><span class="txt_tbl"></span></td>
            </tr>
            <tr>
              <td><span class="txt_tbl">10/26 16:02</span></td>
              <td><span class="txt_tbl">윤*연</span></td>
              <td><span class="txt_tbl">20120726</span></td>
              <td><span class="txt_tbl">도박</span></td>
              <td><span class="txt_tbl">74493</span></td>
              <td><span class="txt_tbl">a-94q_p80APjmJOFOJfdZ</span></td>
              <td><span class="txt_tbl"></span></td>
            </tr>
            <tr>
              <td><span class="txt_tbl">10/24 10:02 </span></td>
              <td><span class="txt_tbl">성*경 </span></td>
              <td><span class="txt_tbl">20080730 </span></td>
              <td><span class="txt_tbl">계정 </span></td>
              <td><span class="txt_tbl">286819 </span></td>
              <td><span class="txt_tbl">GIbif|-4maFaMihQF6zMn</span></td>
              <td><span class="txt_tbl"></span></td>
            </tr>
            <tr>
              <td><span class="txt_tbl">10/22 18:02 </span></td>
              <td><span class="txt_tbl">조*성</span></td>
              <td><span class="txt_tbl">20100303</span></td>
              <td><span class="txt_tbl">사기</span></td>
              <td><span class="txt_tbl">257717</span></td>
              <td><span class="txt_tbl">OfR-14wzBPwVG7WsXkNeE</span></td>
              <td><span class="txt_tbl"></span></td>
            </tr>
          </tbody>
        </template> </TableList
    ></BoxView>
    <BoxView title="KYC 일간 현황" :grid="50">
      <BarChart title="CDD" :chartData="kycDayCDDChartData" />
      <BarChart title="EDD" :chartData="kycDayEDDChartData" />
      <BarChart
        title="보류(무기명 자녀)"
        :chartData="kycDayPanddingChartData"
      />
    </BoxView>
    <BoxView title="STR 보고 현황" :grid="50">
      <BarChart title="STR 보고" :chartData="str3MonthChartData" />
    </BoxView>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import BarChart from '@/adminPackage/components/common/chart/BarChart.vue';
import DoughnutChart from '@/adminPackage/components/common/chart/DoughnutChart.vue';

export default {
  name: 'MonitoringAml',
  components: {
    PageWithLayout,
    BoxView,
    BarChart,
    TableList,
    DoughnutChart,
  },
  data() {
    return {
      kycYearChartData: {
        labels: ['CDD', 'EDD', '보류(무기명 자녀)'],
        datasets: [
          {
            backgroundColor: [
              { color: 'lightPurple', isGradient: true },
              { color: 'lightRed', isGradient: true },
              { color: 'blue', isGradient: false },
            ],
            data: [44823, 517, 35035],
          },
        ],
      },
      kycMonthChartData: {
        labels: ['CDD', 'EDD', '보류(무기명 자녀)'],
        datasets: [
          {
            backgroundColor: [
              { color: 'lightPurple', isGradient: true },
              { color: 'lightRed', isGradient: true },
              { color: 'blue', isGradient: false },
            ],
            data: [1004, 18, 820],
          },
        ],
      },
      strWeekChartData: {
        labels: ['10.13-10.19', '10.20-10.26', '10.27-11.2', '11.3-11.9'],
        datasets: [
          {
            label: '최근 4주간 의심거래 탐지수',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 5,
                  },
                },
              ],
            },
            data: [4, 5, 0, 2],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      strDayMonthChartData: {
        labels: ['11.8', '11.9', '11.10', '11.11', '11.12'],
        datasets: [
          {
            label: '최근 5일간 의심거래 탐지수',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 5,
                  },
                },
              ],
            },
            data: [0, 1, 1, 0, 1],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      kycWeekCDDChartData: {
        labels: [
          '10.6-10.12',
          '10.13-10.19',
          '10.20-10.26',
          '10.27-11.2',
          '11.3-11.9',
        ],
        datasets: [
          {
            label: 'CDD',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 680,
                  },
                },
              ],
            },
            data: [677, 574, 570, 607, 545],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      kycWeekEDDChartData: {
        labels: [
          '10.6-10.12',
          '10.13-10.19',
          '10.20-10.26',
          '10.27-11.2',
          '11.3-11.9',
        ],
        datasets: [
          {
            label: 'EDD',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 680,
                  },
                },
              ],
            },
            data: [9, 12, 12, 16, 9],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      kycWeekPanddingChartData: {
        labels: [
          '10.6-10.12',
          '10.13-10.19',
          '10.20-10.26',
          '10.27-11.2',
          '11.3-11.9',
        ],
        datasets: [
          {
            label: '보류(무기명 자녀)',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 680,
                  },
                },
              ],
            },
            data: [578, 501, 454, 458, 457],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      kycDayCDDChartData: {
        labels: ['11.6', '11.7', '11.8', '11.9', '11.10', '11.11', '11.12'],
        datasets: [
          {
            label: 'CDD',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
              { color: 'green' },
              { color: 'purple' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 120,
                  },
                },
              ],
            },
            data: [58, 89, 69, 66, 79, 86, 115],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      kycDayEDDChartData: {
        labels: ['11.6', '11.7', '11.8', '11.9', '11.10', '11.11', '11.12'],
        datasets: [
          {
            label: 'EDD',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
              { color: 'green' },
              { color: 'purple' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 120,
                  },
                },
              ],
            },
            data: [3, 1, 0, 3, 4, 1, 1],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      kycDayPanddingChartData: {
        labels: ['11.6', '11.7', '11.8', '11.9', '11.10', '11.11', '11.12'],
        datasets: [
          {
            label: '보류(무기명 자녀)',
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
              { color: 'darkYellow' },
              { color: 'darkBulue' },
              { color: 'green' },
              { color: 'purple' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 120,
                  },
                },
              ],
            },
            data: [45, 66, 56, 60, 70, 71, 91],
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        ],
      },
      str3MonthChartData: {
        labels: ['9월', '10월', '11월'],
        datasets: [
          {
            // label: "",
            backgroundColor: [
              { color: 'lightPurple' },
              { color: 'lightRed' },
              { color: 'blue' },
            ],
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: 4,
                  },
                },
              ],
            },
            data: [2, 2, 0],
            legend: { display: false, labels:{display: false} },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.page_monitofing_aml >>> #mArticle{max-width:inherit}
.page_monitofing_aml >>> .body_cont{display:grid;grid-template-columns: 1fr 1fr;}
.box_view{
  display: flex;
  flex-direction: column;
  width:calc(100% - 20px);
  height:400px;
}
.box_view >>> .body_view {
  flex: 1 0;
  display: flex;
  justify-content: space-evenly;
}
.box_view >>> .body_view > div {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.box_view >>> .wrap_data{
  position: relative;
}
.box_view >>> .body_view canvas {
  width: 100%;
  height: auto;
  max-width:100%;
  max-height:310px;
}
.box_view >>> .list_data li{padding:4px;border-radius:6px;background-color:rgba(0,0,0,0.3)}
.box_view >>> .list_data li:nth-child(1){position:absolute;top:50%;left:50%;margin-left:80px}
.box_view >>> .list_data li:nth-child(2){position:absolute;top:50%;right:50%;margin-right:94px}
.box_view >>> .list_data li:nth-child(3){position:absolute;bottom:-10px;left:30%}

.box_view >>> .tbl_list .tbl_body{margin-top:0}
.box_view >>> .tbl_list th,
.box_view >>> .tbl_list td{padding-top:1px;padding-bottom:1px}
</style>
