<template>
  <Popup
    title="바코드결제 전체 점검 등록"
    :maxWidth="646"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    :isDisabeldCompeleteBtn="vaild"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="$emit('onClickPopupComplete',model.getRegisterData())"
  >
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>
            게시기간<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td colspan="3">
            <DateTimePicker :value.sync="model.start_at" />
            <span class="txt_tbl"> ~ </span>
            <DateTimePicker :value.sync="model.end_at" />
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import AllMaintenanceModel from '@/views/service/barcode/maintenance/model/AllMaintenanceModel';
// utill
import { dateTimeDiff } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

export default {
  name: 'AllRegisterPopup',
  components: {
    Popup,
    TableView,
    IconSvg,
    DateTimePicker,
  },
  props: {
    validData: Object,
  },
  data() {
    return {
      model: new AllMaintenanceModel(),
    };
  },
  computed: {
    vaild() {
      return pageValidated(this.validData, this.model) || dateTimeDiff(this.model.start_at, this.model.end_at) > 0;
    },
  },
};
</script>
