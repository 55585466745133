export default class BarcodeCategoryModel {
  constructor() {
    this.objRaw = {};


    this.uid = '';
    this.change_barcode_category_uid = '';
    this.brand_code = '';
    this.goods_code = '';
    this.brand_logo_img_list = [];
    this.brand_logo_img_url = '';
    this.brand_name = '';
    this.payment_category = '';
    this.payback_percent  = null;
    this.use_info = '';
    this.use_branch_info = '';
    this.status = 'WAITING';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      uid,
      change_barcode_category_uid,
      brand_code,
      goods_code,
      brand_logo_img_url,
      brand_name,
      payment_category,
      payback_percent,
      use_info,
      use_branch_info,
      status,
    } = obj;
    

    this.uid = uid;
    this.change_barcode_category_uid = change_barcode_category_uid;
    this.brand_code = brand_code;
    this.goods_code = goods_code;
    this.brand_logo_img_url = brand_logo_img_url;
    this.brand_name = brand_name;
    this.payment_category = payment_category;
    this.payback_percent  = payback_percent;
    this.use_info = use_info;
    this.use_branch_info = use_branch_info;
    this.status = status;

    if(brand_logo_img_url){
      this.brand_logo_img_list = [
        {
          src : brand_logo_img_url,
        }
      ]
    }
  }
  setCategoryUid(uid) {
    this.change_barcode_category_uid = uid;
  }
  getRegisterData() {
    let obj = {
      change_barcode_category_uid: this.change_barcode_category_uid,
      brand_code: this.brand_code,
      goods_code: this.goods_code,
      brand_logo_img_url : this.brand_logo_img_list.length > 0 ? this.brand_logo_img_list[0].src : '',
      brand_name: this.brand_name,
      payment_category: this.payment_category,
      payback_percent: Number(this.payback_percent),
      use_info: this.use_info,
      use_branch_info: this.use_branch_info,
    };
    return obj;
  }
  getModifyData() {
    let obj = {
      change_barcode_category_uid: this.change_barcode_category_uid,
      brand_logo_img_url : this.brand_logo_img_list.length > 0 ? this.brand_logo_img_list[0].src : '',
      brand_name: this.brand_name,
      payment_category: this.payment_category,
      payback_percent: Number(this.payback_percent),
      use_info: this.use_info,
      use_branch_info: this.use_branch_info,
      status: this.status,
    };
    return obj;
  }
}
