<template>
  <PageWithLayout>
    <Tab
      :tabData="viewModel.tabData"
      :value.sync="viewModel.tabData.value"
      @update:value="viewModel.onUpdateTab()"
    />
    <template v-if="viewModel.tabData.value === 'ALL'">
      <div class="tbl_comm tbl_all_top">
        <div class="tbl_top">
          <div class="area_left">
            <strong class="tit_tbl">전체 점검 조회</strong>
          </div>
          <div class="area_right">
            <Button
              btnSize="small"
              btnStyle="primary"
              text="전체 점검 등록"
              @onClickBtn="viewModel.allMaintenanceRegister()"
            />
            <Button
              btnSize="small"
              btnStyle="fourthly"
              text="전체 점검 해제"
              :disabled="!viewModel.allData.uid || isDisabledAll"
              @onClickBtn="viewModel.allMaintenanceUnregister()"
            />
          </div>
        </div>
      </div>
      <TableView
        v-if="viewModel.allData.uid"
        :class="['tbl_all_body', { tbl_disabled: isDisabledAll }]"
      >
        <template v-slot:tbl_colgroup>
          <col style="width:190px" />
          <col />
        </template>
        <template v-slot:tbl_body>
          <tr>
            <th>
              상태
            </th>
            <td>
              <Badge
                :text="
                  viewModel.allData.status | convertIdToText('common_status')
                "
                :badgeStyle="
                  viewModel.allData.status | convertIdToColor('common_status')
                "
                :badgeFill="false"
              />
            </td>
          </tr>
          <tr>
            <th>
              uid
            </th>
            <td>
              <span class="txt_tbl">
                {{ viewModel.allData.uid }}
              </span>
            </td>
          </tr>
          <tr>
            <th>
              점검기간
            </th>
            <td>
              <span class="txt_tbl">{{
                viewModel.allData.start_at
                  | convertDateFormat('YYYY-MM-DD HH:mm:ss')
              }}</span>
              <span class="txt_tbl txt_between"> ~ </span>
              <span :class="['txt_tbl', isEndDate]">
                {{
                  viewModel.allData.end_at
                    | convertDateFormat('YYYY-MM-DD HH:mm:ss')
                }}</span
              >
            </td>
          </tr>
        </template>
        <template v-slot:tbl_bottom>
          <ul class="list_info">
            <li>
              💡 전체 점검 등록시 기존의 전체 점검은 해제되고 새로 등록됩니다.
            </li>
            <li>
              💡 비활성상태이거나 점검기간이 지나면 테이블과 전체 점검
              해제기능이 비활성됩니다.
            </li>
          </ul>
        </template>
      </TableView>
      <p
        v-if="
          viewModel.allDataSearched.isSearched &&
            !viewModel.allDataSearched.hasData
        "
      >
        등록된 전체 점검이 없습니다.
      </p>
    </template>
    <template v-if="viewModel.tabData.value === 'EACH_BRAND'">
      <Board
        :boardData="viewModel.brandListData.boardData"
        :searchParams.sync="viewModel.brandListData.searchParams"
        :dataList="viewModel.brandListData.dataList"
        :paginationData="viewModel.brandListData.paginationData"
      >
        <template v-slot:headRight>
          <Button
            btnSize="small"
            btnStyle="primary"
            text="등록하기"
            @onClickBtn="viewModel.onClickBrandRegister()"
          />
        </template>
      </Board>
    </template>
    <template v-slot:popup>
      <AllRegisterPopup
        v-if="viewModel.isAllRegisterPopup"
        :validData="viewModel.allRegisterValid"
        @onClickPopupClose="viewModel.onClickAllRegisterPopupClose()"
        @onClickPopupComplete="
          data => viewModel.onClickAllRegisterPopupComplete(data)
        "
      />
      <BrandRegisterPopup
        v-if="viewModel.isBrandRegisterPopup"
        :brandSelectList="viewModel.brandSelectList"
        :validData="viewModel.brandRegisterValid"
        @onClickPopupClose="viewModel.onClickBrandRegisterPopupClose()"
        @onClickPopupComplete="
          data => viewModel.onClickBrandRegisterPopupComplete(data)
        "
      />
    </template>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import AllRegisterPopup from '@/views/service/barcode/maintenance/view/popup/AllRegisterPopup';
import BrandRegisterPopup from '@/views/service/barcode/maintenance/view/popup/BrandRegisterPopup';
import { checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

// viewModel
import BarcodeMaintenanceViewModel from '@/views/service/barcode/maintenance/viewModel/BarcodeMaintenanceViewModel';

export default {
  name: 'BarcodeMaintenance',
  components: {
    PageWithLayout,
    Tab,
    TableView,
    Board,
    Button,
    Badge,
    AllRegisterPopup,
    BrandRegisterPopup,
  },
  data() {
    return {
      viewModel: new BarcodeMaintenanceViewModel(),
    };
  },
  computed: {
    isEndDate() {
      return checkPastDate(this.viewModel.allData.end_at, 'tc_red');
    },
    isDisabledAll() {
      return Boolean(
        this.viewModel.allData.status === 'UNREGISTERED' ||
          this.isEndDate === 'tc_red',
      );
    },
  },
  beforeMount() {
    this.viewModel.init();
  },
  methods: {
    checkPastDate(date, returnText) {
      return checkPastDate(date, returnText);
    },
  },
};
</script>
<style>
.tbl_all_top {
  margin-top: 40px;
}
.tbl_all_body {
  margin-top: 12px;
}
.tbl_disabled {
  opacity: 0.4;
}
.tbl_view tbody td .txt_tbl.txt_between {
  padding: 7px 8px;
}
</style>
