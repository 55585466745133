<template>
  <div class="board_comm">
    <TableList :class="[{ 'tbl_list_drag' : dragging }]">
      <template v-slot:tbl_top>
        <div class="area_left" v-if="boardData.title || boardData.subTitle || boardData.option.isTotal">
          <strong class="tit_tbl">{{ boardData.title }}<slot name="tbl_top_title_right"/></strong>
          <p v-if="boardData.subTitle || boardData.option.isTotal" class="desc_tbl">{{ boardData.subTitle || '' }}{{ boardData.option.isTotal ? ` (${paginationData.totalCount || 0})` : '' }}</p>
        </div>
        <slot name="tbl_tab"/>
        <div class="area_right" v-if="hasHeadRight || boardData.option.isSize">
          <div class="group_head_right">
            <slot name="headRight"/>
          </div>
          <Selectbox
            class="selectbox_sort"
            v-if="boardData.option.isSize"
            :dataList="sizeDataList"
            :value.sync="searchParams.pageSize"
            @update:value="onChangePageSize()"/>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col
          v-for="( colData, index ) in boardData.drawDataList"
          :key="`col${index}`"
          :style="`width:${colData.width}`"/>
      </template>
      <template v-slot:tbl_head>
        <template
          v-for="( thData, index ) in boardData.drawDataList">
          <th
            :key="`th${index}`"
            v-if="thData.isCheckbox || thData.isSorting"
            :class="{ align_left : thData.isAlignLeft, align_right : thData.isAlignRight, th_sort : thData.isSorting}">
            <CheckboxOnlyOne
              v-if="thData.isCheckbox"
              id="checkboxOnlyOne"
              :value.sync="checkboxOnlyOneSync"
              @update:value="value => onUpdateCheckAll(value)"/>
            <button
              v-else-if="thData.isSorting"
              :class="['btn_sort', `btn_${searchParams.direction.toLowerCase()}`]"
              @click="onClickSort()"><IconSvg iconName="arrow_bottom" :size="8" iconColor="#222" iconDarkColor="#fff"/><span class="txt_sort">{{ thData.title }}</span></button>
          </th>
          <th
            :key="`th${index}`"
            v-else
            :class="{ align_left : thData.isAlignLeft, align_right : thData.isAlignRight, th_sort : thData.isSorting}"
            v-html="thData.title">
          </th>
        </template>
      </template>
      <template v-slot:tbl_body>
        <draggable
           v-if="dataList && dataList.length > 0"
          :list="dataList"
          tag="tbody"
          :disabled="!(boardData.option.isDrag || false)"
          ghost-class="tr_ghost"
          v-bind="dragOptions"
          :handle="boardData.option.dragHandle"
          @start="dragging = true"
          @end="e => onEndDrag(e)">
          <tr
            v-for="(rowData, rowIndex) in dataList"
            :key="`row_${(rowData[boardData.option.rowIdValue] || rowIndex)}`"
            :class="[
            'tr_link',
            {
              'tr_selected' : (selectedDataList && selectedDataList.find(selected => selected[boardData.option.rowIdValue || 'id'] === (rowData[boardData.option.rowIdValue || 'id']))) || (contextMenuRowData && contextMenuRowData.id === (rowData[boardData.option.rowIdValue || 'id'])),
              'tr_fold' : foldedDataList && foldedDataList.find(selected => selected === (rowData[boardData.option.rowIdValue || 'id'])),
              'tr_bold' : rowData.isFixed,
            }]"
            @click.prevent="onClickRow((rowData[boardData.option.rowIdValue || 'id']),rowData)"
            @contextmenu.prevent="e => onClickContextMenu(e,rowData)">
            <BoardTd
              v-for="(tdData, index) in boardData.drawDataList"
              :key="`td${index}`"
              :rowIndex="rowIndex"
              :rowData="rowData"
              :tdData="tdData"
              :selectedDataList.sync="selectedDataListSync"
              :foldedDataList.sync="foldedDataListSync"
              :markData="boardData.markData"
              @onUpdateCheckItem="value => onUpdateCheckItem(value)"/>
          </tr>
        </draggable>
        <tbody v-else>
          <tr class="tr_empty">
            <td class="td_empty" :colspan="boardData.drawDataList.length">{{ emptyDesc }}</td>
          </tr>
        </tbody>
      </template>
      <template v-slot:tbl_foot>
        <tr v-if="footData">
          <BoardTd
            v-for="( tfData, index ) in boardData.drawFootDataList"
            :key="`tf${index}`"
            :colspan="tfData.colspan || 1"
            :rowData="footData"
            :tdData="tfData"
            :selectedDataList.sync="selectedDataListSync"
            :foldedDataList.sync="foldedDataListSync"
            :markData="boardData.markData"
            @onUpdateCheckItem="value => onUpdateCheckItem(value)"/>
          <!-- <td
            v-for="( tfData, index ) in boardData.drawFootDataList"
            :key="`th${index}`"
            :colspan="tfData.colspan || 1"
            :class="{ align_left : tfData.isAlignLeft}">
            <strong>{{ tfData.title }}</strong>
          </td> -->
        </tr>
      </template>
    </TableList>
    <ContextMenu
      :contextMenu="contextMenu"
      :isContextMenu="isContextMenu"
      :contextMenuCssProps="contextMenuCssProps"
      :contextMenuRowData="contextMenuRowData"/>
    <!-- <template v-if="dataList.length === 0">
      <div class="area_empty">
        <p class="desc_empty">{{ emptyDesc }}</p>
      </div>
    </template> -->
    <Pagination
      v-if="boardData.option.isSize"
      :searchParams="searchParams"
      :paginationData="paginationData"
      @onChangePage="onChangePage"/>
  </div>
</template>

<script>
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import CheckboxOnlyOne from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxOnlyOne';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import draggable from "vuedraggable";
import Pagination from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Pagination';
import BoardTd from '@lemontree-ai/lemontree-admin-common-front/components/common/board/BoardTd';
import ContextMenu from '@lemontree-ai/lemontree-admin-common-front/components/common/contextMenu/ContextMenu';
import ContextMenuMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/ContextMenuMixin';

export default {
  name: 'Board',
  mixins: [ ContextMenuMixin ],
  components:{
    TableList,
    Button,
    Selectbox,
    CheckboxOnlyOne,
    IconSvg,
    draggable,
    BoardTd,
    Pagination,
    ContextMenu,
  },
  props:{
    boardData: Object,
    searchParams: Object,
    dataList: Array,
    footData: Object,
    contextMenu: Array,
    checkboxOnlyOne: Boolean,
    selectedDataList: Array,
    foldedDataList: Array,
    paginationData: Object,
    emptyDesc:{
      type: String,
      default: '데이터가 없습니다.'
    }
  },
  data(){
    return{
      sizeDataList:[
        { id:10, text:'10' },
        { id:20, text:'20' },
        { id:30, text:'30' },
        { id:50, text:'50' },
        { id:100, text:'100' },
      ],
      dragging: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  computed:{
    hasHeadRight(){
      return this.$slots['headRight']
    },
    searchParamsSync:{
      get(){
        return this.searchParams;
      },
      set(value){
        this.$emit('update:searchParams',value)
      }
    },
    checkboxOnlyOneSync:{
      get(){
        return this.checkboxOnlyOne;
      },
      set(value){
        this.$emit('update:checkboxOnlyOne',value)
      }
    },
    selectedDataListSync:{
      get(){
        return this.selectedDataList;
      },
      set(value){
        this.$emit('update:selectedDataList',value)
      }
    },
    foldedDataListSync:{
      get(){
        return this.foldedDataList;
      },
      set(value){
        this.$emit('update:foldedDataList',value)
      }
    },
  },
  methods:{
    onClickSort(){
      const direction = this.searchParamsSync.direction;
      let result = 'DESC'
      switch(direction){
        case 'DESC' : { result = 'ASC'; break }
        case 'ASC' : { result = 'DESC'; break }
      }
      this.searchParamsSync.direction = result;
      this.$emit('onSearch');
    },
    onClickRow(id,rowData){
      this.$emit('onClickRow',id,rowData)
    },
    onUpdateCheckAll(value){
      if(value){
        this.selectedDataListSync = this.dataList
      }else{
        this.selectedDataListSync = [];
      }
    },
    onUpdateCheckItem(value){
      if(value.length === this.dataList.length && !this.checkboxOnlyOne){
        // console.log(this.checkboxOnlyOneSync,'expect:true')
        this.checkboxOnlyOneSync = true;
      }
      if(value.length != this.dataList.length && this.checkboxOnlyOne){
        // console.log(this.checkboxOnlyOneSync,'expect:false')
        this.checkboxOnlyOneSync = false;
      }
    },
    onChangePageSize(){
      this.searchParamsSync.page = 0;
      this.setQuery();
      this.$emit('onSearch');
    },
    onChangePage(toPageIndex){
      this.searchParamsSync.page = toPageIndex;
      this.setQuery();
      this.$emit('onSearch');
    },
    onEndDrag(e,data){
      this.dragging = false;
      this.boardData.option.onEndDrag(e,data)
    },
    setQuery(){
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query));
      let addQuery = {
        pageSize : String(this.searchParamsSync.pageSize),
        page : String(this.searchParamsSync.page),
      };
      let newQuery = Object.fromEntries(Object.entries(Object.assign(currentQuery,addQuery)).filter(([_, v]) => v != '' && v != undefined  && v != 'undefined' ));
      this.$router.replace({ query : newQuery}).catch(()=>{});
    }
  }
}
</script>

<style lang="scss" scoped>
.tab_comm + .board_comm,
.board_comm + .board_comm,
.tbl_view + .board_comm,
.tbl_list + .board_comm,
.fold_comm + .board_comm{margin-top:40px}
.search_comm + .board_comm{margin-top:60px}

.group_head_right{display:inline-block;vertical-align:top}
.group_head_right:only-child::v-deep .txt_right:only-child{display:block;padding-top:17px}

.tbl_list tr th.th_sort{padding-right:6px;text-overflow:clip}
.btn_sort{display:block;width:100%;font-weight:700}
.btn_sort .txt_sort{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
.btn_sort .icon_arrow_bottom{float:right;margin:5px 0 8px 6px}
.btn_sort.btn_desc .icon_arrow_bottom{transform:scaleY(-1)}

// .area_empty{padding:100px 0;text-align:center}
.tbl_list tr td.td_empty{padding:20px 0;background-color:$DISABLED_BG;color:$DISABLED_TEXT;text-align:center}
.tbl_list tr td.td_empty:hover{background-color:$DISABLED_BG}
.tbl_list table tbody:first-child td.td_empty{border:0 none}


@media (prefers-color-scheme: dark) {
  // .area_empty{border-bottom-color:$DEFAULT_BORDER_DARK;background-color:$DISABLED_BG_DARK}
  .tbl_list tr td.td_empty{background-color:$DISABLED_BG_DARK;color:$DISABLED_TEXT_DARK}
  .tbl_list tr td.td_empty:hover{background-color:$DISABLED_BG_DARK}
}
/* 모바일 */
@media all and (max-width:1240px){
  .tab_comm + .board_comm{margin-top:20px}
  // .area_empty{border-top:1px solid $DEFAULT_BORDER}
  .tbl_list .tr_empty{padding:0}
  .tbl_list tr td.td_empty{width:100%;text-align:center}
}
/* 모바일 */
@media all and (max-width:600px){
  .search_comm + .board_comm{margin-top:0}
}
</style>