<script>
import { Bar } from "vue-chartjs";
import ChartMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/ChartMixin';

export default {
  extends: Bar,
  mixins: [ChartMixin],
  props:{
    chartData: Object,
    isStacked:{
      type: Boolean,
      default: false
    }
  },
};
</script>
