<script>
import { Doughnut } from "vue-chartjs";
import ChartMixin from '@/adminPackage/mixins/ChartMixin';

export default {
  extends: Doughnut,
  mixins: [ChartMixin],
  props:{
    title: String,
    chartData: Object,
  },
};
</script>