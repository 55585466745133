<template>
  <Popup
    title="브랜드별 점검 등록"
    :maxWidth="646"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    :isDisabeldCompeleteBtn="vaild"
    @onClickClose="$emit('onClickPopupClose')"
    @onClickComplete="$emit('onClickPopupComplete',model.getRegisterData())"
  >
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>
            브랜드 선택<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <Selectbox
              :dataList="brandSelectList | setDataList('uid', 'brand_name')"
              :value.sync="model.brand_uid"
              thumnailKey="brand_logo_img_url"
              :thumnailSize="[28, 28]"
              @update:value="value => onSetBrandCode(value)"
            />
          </td>
        </tr>
        <tr>
          <th>
            게시기간<IconSvg
              iconName="essential"
              :size="6"
              iconColor="#F04848"
            />
          </th>
          <td>
            <DateTimePicker :value.sync="model.start_at" />
            <span class="txt_tbl"> ~ </span>
            <DateTimePicker :value.sync="model.end_at" />
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import BrandMaintenanceModel from '@/views/service/barcode/maintenance/model/BrandMaintenanceModel';
// utill
import { dateTimeDiff } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

export default {
  name: 'BrandRegisterPopup',
  components: {
    Popup,
    TableView,
    IconSvg,
    Selectbox,
    DateTimePicker,
  },
  props: {
    brandSelectList: Array,
    validData: Object,
  },
  data() {
    return {
      model: new BrandMaintenanceModel(),
    };
  },
  computed: {
    vaild() {
      return pageValidated(this.validData, this.model) || dateTimeDiff(this.model.start_at, this.model.end_at) > 0;
    },
  },
  methods: {
    dateTimeDiff(val1, val2){
      return dateTimeDiff(val1, val2)
    },
    onSetBrandCode(value) {
      if (!value) return;
      const brandItem = this.brandSelectList.find(item => item.uid === value);
      if (!brandItem) return;
      console.log(brandItem)
      this.model.brand_code = brandItem.brand_code;
    },
  },
};
</script>
