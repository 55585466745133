<template>
  <PageWithLayout :isBodyFull="true">
    <div class="wrap_flex">
      <div class="group_category">
        <GroupCategory
          :groupCategoryData.sync="viewModel.groupCategoryDrawData.CATEGORY"
          :dataList.sync="viewModel.dataViewModel.CATEGORY.dataList"
          :value.sync="viewModel.dataViewModel.CATEGORY.value"
          rowIdValue="uid"
          @onClickAdd="categoryId => viewModel.onClickAdd(categoryId)"
          @onClickCategoryItem="
            (categoryId, rowData) =>
              viewModel.onSelectCategoryItem(categoryId, rowData)
          "
          @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)"
        >
          <template v-slot:item_category="slotProps">
            <span class="txt_item">
              <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
            </span>
            <span class="txt_item">
              <span
                class="box_color"
                :style="
                  `background-color:${$options.filters.convertIdToColor(
                    slotProps.itemData.status,
                    'barcode_status_hex',
                  )}`
                "
              ></span>
            </span>
            <span
              class="txt_item img_item"
              :style="`background-color:${slotProps.itemData.color_code}`"
            >
              <img
                class="img_thumnail"
                :src="slotProps.itemData.icon_img_url"
                alt=""
              />
            </span>
            <strong class="tit_item" style="width:80px;margin:0 14px 0 12px;">{{
              slotProps.itemData.category_name
            }}</strong>
            <span class="tit_item fill_item">
              {{ slotProps.itemData.category_description }}
            </span>
            <!-- <span
              class="txt_item"
              :style="
                `background-color:${$options.filters.convertIdToColor(
                  slotProps.itemData.color,
                  'barcode_category_color_text',
                )}`
              "
              >{{
                slotProps.itemData.color
                  | convertIdToText('barcode_category_color_text')
              }}</span
            > -->
          </template>
        </GroupCategory>
        <GroupCategory
          :groupCategoryData.sync="viewModel.groupCategoryDrawData.BRAND"
          :dataList.sync="viewModel.dataViewModel.BRAND.dataList"
          :value.sync="viewModel.dataViewModel.BRAND.value"
          rowIdValue="brand_code"
          :upperData="{
            title: viewModel.groupCategoryDrawData.CATEGORY.title,
            value: viewModel.dataViewModel.CATEGORY.value,
          }"
          @onClickAdd="categoryId => viewModel.onClickAdd(categoryId)"
          @onClickCategoryItem="
            (categoryId, rowData) =>
              viewModel.onSelectCategoryItem(categoryId, rowData)
          "
          @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)"
        >
          <template v-slot:item_category="slotProps">
            <span class="txt_item">
              <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
            </span>
            <span class="txt_item">
              <span
                class="box_color"
                :style="
                  `background-color:${$options.filters.convertIdToColor(
                    slotProps.itemData.status,
                    'barcode_status_hex',
                  )}`
                "
              ></span>
            </span>
            <strong class="txt_item img_item">
              <img
                class="img_thumnail"
                :src="slotProps.itemData.brand_logo_img_url"
                alt=""
              />
            </strong>
            <strong class="tit_item fill_item">{{
              slotProps.itemData.brand_name
            }}</strong>
            <span class="txt_item">{{
              slotProps.itemData.payment_category
                | convertIdToText('barcode_payment_category')
            }}</span>
            <span class="txt_item"
              >{{ slotProps.itemData.payback_percent }}%</span
            >
          </template>
        </GroupCategory>
      </div>
      <div class="area_preview">
        <div class="item_category" v-for="item in viewModel.dataList" :key="item.uid">
          <strong
            class="tit_category"
            :style="
            `background-color: ${$options.filters.convertIdToColor(item.barcode_category_dto.color,'barcode_category_color_bg')};
            border-color: ${$options.filters.convertIdToColor(item.barcode_category_dto.color,'barcode_category_color_border')}`"
          >
            <img
              class="img_category"
              :src="item.barcode_category_dto.icon_img_url"
              alt=""
            />
            {{ item.barcode_category_dto.category_description }}
          </strong>
          <ul class="list_brand">
            <li
              v-for="brandItem in item.barcode_brand_dto_list"
              :key="brandItem.uid"
            >
              <img
                class="img_brand"
                :src="brandItem.brand_logo_img_url"
                alt=""
              />
              <strong class="tit_brand">{{ brandItem.brand_name }}</strong>
              <p class="desc_brand">{{ brandItem.payback_percent }}% 적립</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template v-slot:popup>
      <CategoryRegisterPopup
        v-if="viewModel.groupCategoryDrawData.CATEGORY.isRegisterPopup"
        :mappingViewModel="viewModel"
        @onClickPopupClose="viewModel.onClickPopupClose('CATEGORY')"
        @onClickPopupComplete="
          data => viewModel.onClickPopupComplete('CATEGORY', data)
        "
      />
      <BrandRegisterPopup
        v-if="viewModel.groupCategoryDrawData.BRAND.isRegisterPopup"
        :mappingViewModel="viewModel"
        @onClickPopupClose="viewModel.onClickPopupClose('BRAND')"
        @onClickPopupComplete="
          data => viewModel.onClickPopupComplete('BRAND', data)
        "
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// popup
import CategoryRegisterPopup from '@/views/service/barcode/mapping/view/popup/CategoryRegisterPopup';
import BrandRegisterPopup from '@/views/service/barcode/mapping/view/popup/BrandRegisterPopup';

// viewModel
import BarcodeMappingListViewModel from '@/views/service/barcode/mapping/viewModel/BarcodeMappingListViewModel';

export default {
  name: 'BarcodeBrandMapping',
  components: {
    PageWithLayout,
    GroupCategory,
    IconSvg,

    CategoryRegisterPopup,
    BrandRegisterPopup,
  },
  data() {
    return {
      viewModel: new BarcodeMappingListViewModel(),
    };
  },
  beforeMount() {
    this.viewModel.init();
  },
};
</script>

<style scoped>
.wrap_flex {
  display: flex;
  flex: 1 1 100%;
  height: calc(100% + 20px);
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.area_preview {
  width: 240px;
  overflow-y:auto;
  color:#fff;
  text-align:center;
  background-color:#F3F1F1;
}
.area_preview .item_category{padding:0 10px}
.area_preview .item_category + .item_category{margin-top:20px}
.area_preview .tit_category{display:inline-block;margin:0 auto;padding:4px 10px;border-radius:20px;font-size:14px;line-height:20px;font-weight:700;border:2px solid ;color:#111;vertical-align:top}
.area_preview .img_category{display:inline-block;width:20px;height:20px;vertical-align:top}

.area_preview .list_brand{display:grid;margin-top:8px;grid-template-columns: 1fr 1fr;gap:8px}
.area_preview .list_brand li{padding:8px;border-radius:10px;background-color:#fff;text-align:center;}
.area_preview .list_brand .img_brand{display:inline-block;width:32px;height:32px;border-radius:100%;border:1px solid #E7E5E5;vertical-align:top}
.area_preview .list_brand .tit_brand{display:block;margin-top:4px;font-size:12px;line-height:18px;font-weight:700;color:#232323}
.area_preview .list_brand .desc_brand{font-size:10px;line-height:14px;font-weight:700;color:#F77833}


.group_category {
  flex:1 0;
}
.group_category .area_category:nth-child(1) {
  width: 40%;
}
.group_category .area_category:nth-child(2) {
  width: 60%;
}
.img_item {
  width: 32px;
  height: 32px;
  margin: -5px 0 -6px;
  padding: 2px;
  border-radius: 6px;
  box-sizing: border-box;
}
.img_thumnail {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 4px;
}
.layer_dimmed {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.layer_dimmed p {
  flex: 1 0;
  padding-left: 200px;
  padding-bottom: 100px;
  text-align: center;
  font-size: 24px;
}
</style>
