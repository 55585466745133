const serviceMenuData =
[
  {
    title: '퍼핀페이 회원관리',
    id: 'USER',
    menuIcon: '👤',
    menuList: [ 
      {
        title: '회원정보',
        id: 'SEARCH',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_USER_SEARCH_LIST',
      },
      {
        title: '회원정보+',
        id: 'PLUS',
        requiresMinRole: 'ADMIN',
        routerPath: 'SERVICE_USER_PLUS_LIST',
      },
      {
        title: '탈퇴 회원관리',
        id: 'LEAVE',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_USER_LEAVE_LIST',
      },
    ]
  },
  {
    title: '멤버십관리',
    id: 'MEMBERSHIP',
    menuIcon: '🎖',
    menuList: [ 
      {
        title: '구매내역 조회',
        id: 'PAYMENTS',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_MEMBERSHIP_PAYMENTS_LIST',
      },
    ]
  },
  {
    title: '카드신청서',
    id: 'CARD',
    menuIcon: '💳',
    routerPath: 'SERVICE_CARD_LIST',
  },
  {
    title: '약관관리',
    id: 'TERM',
    menuIcon: '🖌',
    routerPath: 'SERVICE_TERM_LIST',
  },
  {
    title: '포인트관리',
    id: 'POINT',
    menuIcon: '🎁',
    menuList: [ 
      {
        title: '개별 지급하기',
        id: 'INDIVIDUAL',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_POINT_INDIVIDUAL',
      },
      {
        title: '가족별 지급하기',
        id: 'REWARD',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_POINT_REWARD',
      },
      {
        title: '가족별 지급내역 조회',
        id: 'HISTORY',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_POINT_HISTORY',
      },
    ]
  },
  {
    title: '입금이체 은행관리',
    id: 'BANK',
    menuIcon: '🏦',
    routerPath: 'SERVICE_BANK',
  },
  // {
  //   title: '문의관리',
  //   id: 'INQUIRY',
  //   menuIcon: '💁‍♀️',
  //   routerPath: 'SERVICE_INQUIRY_LIST',
  // },
  // {
  //   title: 'FAQ관리',
  //   id: 'FAQ',
  //   menuIcon: '💬',
  //   routerPath: 'SERVICE_FAQ',
  // },
  {
    title: '바코드관리',
    id: 'BARCODE',
    menuIcon: '🎹',
    menuList: [ 
      {
        title: '카테고리/브랜드 관리',
        id: 'MAPPING',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_BARCODE_MAPPING',
      },
      {
        title: '점검관리',
        id: 'MAINTENANCE',
        requiresMinRole: 'USER',
        routerPath: 'SERVICE_BARCODE_MAINTENANCE',
      },
    ]
  },
]
export { serviceMenuData }