import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import AllMaintenanceModel from '@/views/service/barcode/maintenance/model/AllMaintenanceModel';
import { checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class BarcodeMaintenanceViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'ALL', text: '전체 점검' },
        { id: 'EACH_BRAND', text: '브랜드 점검' },
      ],
      option: {
        isCount: false,
      },
      value: 'ALL',
    };

    // 전체
    this.allData = new AllMaintenanceModel();
    this.allDataSearched = {
      isSearched: false,
      hasData: false,
    };
    this.allRegisterValid = {
      start_at: {
        value: 'start_at',
        valueType: '',
        isValid: false,
        validAlertDesc: '',
      },
      end_at: {
        value: 'end_at',
        valueType: '',
        isValid: false,
        validAlertDesc: '',
      },
    };

    // 브랜드
    this.brandListData = {
      dataList: [],
      boardData: {
        title: '브랜드 점검 목록',
        drawDataList: [
          {
            title: '브랜드명',
            width: '200px',
            value: 'brand_name',
            isAlignLeft: true,
          },
          {
            title: '브랜드 코드',
            width: '80px',
            value: 'brand_code',
            isAlignLeft: true,
          },
          {
            title: '브랜드 UID',
            width: '210px',
            value: 'brand_uid',
            isAlignLeft: true,
          },
          {
            title: '점검기간',
            width: '',
            value: 'start_at',
            dateRangeValue: {
              format: 'YYYY-MM-DD HH:mm:ss',
              startValue: 'start_at',
              endValue: 'end_at',
              betweenText: ' ~<br>',
              isEndPastDateToRed: true,
            },
            isAlignLeft: true,
          },
          {
            title: '상태',
            width: '78px',
            value: 'status',
            filter: {
              name: 'convertIdToText',
              value: 'common_status',
            },
            badge: {
              badgeColorFilter: {
                name: 'convertIdToColor',
                dataListName: 'common_status',
              },
              badgeFill: false,
            },
          },
          {
            title:'해제',
            width:'76px',
            value:'disabled',
            buttonCondition: [
              { condition:false, text:'해제', size:'small', style:'fourthly_border', disabled:false, onClickEvent: (data) => this.onClickBrandUnregister(data) },
              { condition:true, text:'해제', size:'small', style:'fourthly_border', disabled:true },
            ],
          },
        ],
        option: {
          isTotal: true,
          isSize: false,
          rowIdValue: 'uid',
        },
      },
      searchData: {
        searchParams: {
          direction: 'ASC',
          pageSize: 200,
        },
        value: '',
      },
      paginationData: {
        totalCount: 0,
      },
    };
    this.brandSelectList = [];
    this.brandRegisterValid = {
      name: {
        value: 'brand_code',
        valueType: '',
        isValid: false,
        validAlertDesc: '',
      },
      email: {
        value: 'brand_uid',
        valueType: '',
        isValid: false,
        validAlertDesc: '',
      },
      start_at: {
        value: 'start_at',
        valueType: '',
        isValid: false,
        validAlertDesc: '',
      },
      end_at: {
        value: 'end_at',
        valueType: '',
        isValid: false,
        validAlertDesc: '',
      },
    };

    this.isAllRegisterPopup = false;
    this.isBrandRegisterPopup = false;
  }

  init() {
    this.getListData();
  }
  
  // --- 전체 ---
  // 등록
  allMaintenanceRegister() {
    this.isAllRegisterPopup = true;
  }
  // 해제
  allMaintenanceUnregister() {
    const requestUnregister = () => {
      this.postAllUnregister();
    };
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `전체 점검을 정말 해제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestUnregister,
    });
  }
  // 팝업닫기
  onClickAllRegisterPopupClose() {
    this.isAllRegisterPopup = false;
  }
  // 팝업완료
  onClickAllRegisterPopupComplete(data){
    this.postAllRegister(data);
  }

  // --- 브랜드 ---
  // 등록
  onClickBrandRegister() {
    this.getBrandAllList();
    this.isBrandRegisterPopup = true;
  }
  // 해제
  onClickBrandUnregister(data){
    this.postBrandUnregister(data)
  }
  // 팝업닫기
  onClickBrandRegisterPopupClose() {
    this.isBrandRegisterPopup = false;
  }
  // 팝업완료
  onClickBrandRegisterPopupComplete(data){
    this.postBrandRegister(data);

  }

  // 탭 업데이트
  onUpdateTab() {
    this.getListData();
  }
  // 탭 내 데이터 가져오기
  getListData() {
    switch (this.tabData.value) {
      case 'ALL': {
        this.getAllData();
        break;
      }
      case 'EACH_BRAND': {
        this.getBrandList();
        break;
      }
    }
  }

  // [API] 전체 점검 조회
  getAllData() {
    this.allDataSearched.isSearched = false;
    this.allDataSearched.hasData = false;
    const path = `${apiPath.BARCODE_MAINTENANCE_ALL}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.allData.setData(resultData.body);
        this.allDataSearched.isSearched = true;
        this.allDataSearched.hasData = Boolean(resultData.body.uid);
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 전체 점검 등록
  postAllRegister(data){
    const path = `${apiPath.BARCODE_MAINTENANCE_ALL_REGISTER}`;
    const body = {
      body: data,
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
      success => {
        this.isAllRegisterPopup = false;
        store.dispatch(
          'commonToast/fetchToastStart',
          '전체 점검이 등록되었습니다',
        );
        this.getAllData();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 전체 점검 해제
  postAllUnregister() {
    const path = `${apiPath.BARCODE_MAINTENANCE_ALL_UNREGISTER}`;
    POST_REQUEST(path).then(
      success => {
        store.dispatch(
          'commonToast/fetchToastStart',
          '전체 점검이 해제되었습니다',
        );
        this.getAllData();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

  // [API] 브랜드 점검 목록
  getBrandList() {
    const path = `${apiPath.BARCODE_MAINTENANCE_BRAND_LIST}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        const resultList = resultData.body.barcode_brand_maintenance_list.map(item => {
            item.disabled =
              Boolean(checkPastDate(item.end_at, 'yesIsPast')) ||
              item.status === 'UNREGISTERED';
            return item;
        });

        this.brandListData.dataList = resultList;
        this.brandListData.paginationData.totalCount = resultList.length;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 브랜드 점검 해제
  postBrandUnregister(data) {
    const path = `${apiPath.BARCODE_MAINTENANCE_BRAND_UNREGISTER.format(data.brand_uid)}`;
    GET_REQUEST(path).then(
      success => {
        store.dispatch(
          'commonToast/fetchToastStart',
          '브랜드 점검이 해제되었습니다',
        );
        this.getBrandList();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 전체 브랜드 조회
  getBrandAllList() {
    const query = makeQueryStringByObject({ pageSize: 1000, page: 0 });
    const path = `${apiPath.BARCODE_BRAND_LIST}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.brandSelectList = resultData.body;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 브랜드 점검 등록
  postBrandRegister(data){
    const path = `${apiPath.BARCODE_MAINTENANCE_BRAND_REGISTER}`;
    const body = {
      body: data,
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
      success => {
        this.isBrandRegisterPopup = false;
        store.dispatch(
          'commonToast/fetchToastStart',
          '브랜드 점검이 등록되었습니다',
        );
        this.getBrandList();
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

}
