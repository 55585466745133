import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class BrandMaintenanceModel {
  constructor() {
    this.brand_uid = '';
    this.brand_code = '';
    this.start_at = '';
    this.end_at = '';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const { brand_uid, brand_code, start_at, end_at } = obj;
    this.brand_uid = brand_uid;
    this.brand_code = brand_code;
    this.start_at = start_at;
    this.end_at = end_at;
  }
  getRegisterData() {
    let obj = {
      brand_uid: this.brand_uid,
      brand_code: this.brand_code,
      start_at: convertDateFormat(this.start_at, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      end_at: convertDateFormat(this.end_at, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    };
    return obj;
  }
}
